export enum EPropertyTypesUI {
  MULTI = 'Multi',
  SINGLE = 'Single',
}
export enum EPropertyTypes {
  MULTI = 'MULTI',
  SINGLE = 'SINGLE',
}

export enum ESpeciesTypesUI {
  CAT = 'Cat',
  DOG = 'Dog',
}
export enum ESpeciesTypes {
  CAT = 'CAT',
  DOG = 'DOG',
}

export type TModuleUI = {
  title: string;
  order: number;
  duration: number;
  passed: boolean;
  photo: string;
  video: string;
};
export type TModule = TModuleUI;

export type TTrainingMainInfoUI = {
  id: string;
  name: string;
  photo: string;
  duration: number;
  modulesCount: number;
  passed: boolean;
  required: boolean;
};
export type TTrainingMainInfo = TTrainingMainInfoUI;

export type TTrainingUI = TTrainingMainInfo & {
  propertyTypes: EPropertyTypesUI[];
  speciesTypes: ESpeciesTypesUI[];
};
export type TTraining = TTrainingMainInfo & {
  propertyTypes: EPropertyTypes[];
  speciesTypes: ESpeciesTypes[];
};

export type TTrainingDetailsUI = TTrainingUI & {
  modules: TModuleUI[];
};
export type TTrainingDetails = TTraining & {
  subCourses?: TModule[];
};

export type TTrainingAccessUI = TTrainingDetailsUI | null;
export type TTrainingAccess = TTrainingDetails | null;

export type TTrainingPassedUpdateUI = {
  courseId: string;
  checkpointKey: string;
};
