export enum EDocPacketUrls {
  PROFILE = 'profile',
  HP_DOCUMENT_PACKET = 'hp-document-packet',
  HP_CONTACT = 'hp-contact',
  NAME = 'name',
  PHONE = 'phone',
  EMAIL = 'email',
  FAX_NUMBER = 'fax-number',
  HP_VISITATION = 'hp-visitation',
  HP_LETTER = 'hp-letter',
  TEMPLATES = 'templates',
}
