import { RoutesPaths } from 'routes/Routes.types';
import { ENotificationUI } from 'data/types/notifications.types';

export type TNotification = { private: boolean; url: string };
export const notificationsMapper: {
  [key in ENotificationUI]: TNotification;
} = {
  [ENotificationUI.ANIMAL_APPLICATION_APPROVED]: {
    private: true,
    url: `${RoutesPaths.APPLICATION_DETAILS}`,
  },
  [ENotificationUI.ANIMAL_APPLICAION_REJECTED]: {
    private: true,
    url: `${RoutesPaths.APPLICATION_DETAILS}`,
  },
};
