import cloneDeep from 'lodash/cloneDeep';
import { TAuthConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  TConfirmSignUpAmplifyDataToDb,
  TConfirmSignUpAmplifyDataUi,
  ICurrentAuthUser,
  TCurrentAuthUserUI,
  TForgotPasswordSubmitAmplifyDataUI,
  TForgotPasswordSubmitAmplifyToDb,
} from 'data/types/amplify.types';

export const confirmSignUpAmplifyConvertor: TAuthConverter<
  TConfirmSignUpAmplifyDataUi,
  TConfirmSignUpAmplifyDataToDb,
  void,
  void
> = {
  fromDb: () => {
    /* do nothing */
  },
  toDb: data => ({
    username: data.username,
    verificationCode: data.verificationCode,
  }),
};

export const forgotPasswordSubmitAmplifyConvertor: TAuthConverter<
  TForgotPasswordSubmitAmplifyDataUI,
  TForgotPasswordSubmitAmplifyToDb,
  void,
  void
> = {
  fromDb: () => {
    /* do nothing */
  },
  toDb: data => ({
    username: data.username,
    verificationCode: data.verificationCode,
    newPassword: data.newPassword,
  }),
};

export const currentAuthUserConvertor: TFetchConverter<
  TCurrentAuthUserUI,
  ICurrentAuthUser
> = {
  fromDb: data => {
    const cognitoUser = cloneDeep(data);
    cognitoUser.username = data.username;
    cognitoUser.name = data.attributes?.name;
    cognitoUser.challengeName = data.challengeName;
    if (data.attributes && 'email' in data.attributes) {
      cognitoUser.email = data.attributes?.email;
    }
    if (data.attributes && 'phone_number' in data.attributes) {
      cognitoUser.phoneNumber = data.attributes?.phone_number;
    }

    return cognitoUser;
  },
};
