import React, { FC } from 'react';
import { SPageSpinner, SSpinner } from './Spinner.style';
import { TSpinnerProps, TSpinnerSize } from './Spinner.type';

export const PageSpinner: FC<TSpinnerProps> = props => {
  return <SPageSpinner {...props} />;
};

export const Spinner: FC<TSpinnerProps> = ({
  className,
  centered,
  ...props
}) => {
  return (
    <SSpinner
      className={`${className} ${centered ? 'centered' : ''}`}
      {...props}
    />
  );
};

export type { TSpinnerSize };
