import {
  TEditingField,
  TOptionType,
  TOptionTypeUi,
  TSelectValueUI,
  TYesNoOptionType,
} from 'data/types/generalTypes';
import { TFile } from 'data/types/storage.types';

export enum EVisitationStatusUI {
  PENDING = 'Pending',
  REJECTED = 'Not approved',
  APPROVED = 'Approved',
  ARCHIVED = 'Archived',
}
export enum EVisitationStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ARCHIVED = 'ARCHIVED',
}

export enum EAuthorizationStatusUI {
  AUTHORIZED = 'Authorized',
  UN_AUTHORIZED = 'Unauthorized',
}
export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  UN_AUTHORIZED = 'UN_AUTHORIZED',
}

export type TPropertyManagementUI = {
  name: string;
  logo: string | null;
  propertyName: string;
  unitNumber: string;
};
export type TPropertyManagement = {
  name: string;
  logo: string | null;
  propertyName: string;
  unitNumber: string;
};

export type TCreateVisitation = {
  isServiceAnimal: TYesNoOptionType;
  requiredBecauseOfDisability: TYesNoOptionType;
  trainedFor?: TYesNoOptionType;
  residenceId: string;
};

export type TApplyForVisitation = {
  name: string;
  profilePicture?: TFile | null;
  species: TOptionTypeUi;
  breed?: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1?: TOptionTypeUi;
  secondaryColor2?: TOptionTypeUi;
  startDateTime: string;
  endDateTime: string;
  explanation: string;
  willBeLeftWithoutSupervision: TYesNoOptionType;
  precautions?: string;
  didUserConfirmLearningEfforts: boolean;
};

export type TVisitationFormDataUI = TCreateVisitation & TApplyForVisitation;

export type TVisitationFormData = {
  residenceId: string | null;
  name: string;
  profilePicture?: string | null;
  species: TOptionType;
  breed?: TOptionType | null;
  primaryColor?: TOptionType;
  secondaryColor1?: string | null;
  secondaryColor2?: string | null;
  startDate: string;
  endDate: string;
  explanation: string;
  willBeLeftWithoutSupervision?: boolean | null;
  precautions?: string;
  didUserConfirmLearningEfforts: boolean;
  isServiceAnimal?: boolean | null;
  requiredBecauseOfDisability?: boolean | null;
  trainedFor: string | null;
};

export type TVisitationUI = {
  id: string;
  animalName: string;
  animalProfilePicture: string | null;
  species: TSelectValueUI;
  breed: TSelectValueUI;
  primaryColor: TSelectValueUI;
  secondaryColor1: TSelectValueUI;
  secondaryColor2: TSelectValueUI;
  startDate: string;
  endDate: string;
  explanation: string;
  willBeLeftWithoutSupervision: TYesNoOptionType;
  precautions: string;
  didUserConfirmLearningEfforts: boolean;
  status: EVisitationStatusUI;
  isServiceAnimal: boolean;
  trainedFor: string;
  requiredBecauseOfDisability: TYesNoOptionType;
  authorization: EAuthorizationStatusUI;
  propertyManagement: TPropertyManagementUI;
  rejectReason: string | null;
  canEdit: boolean;
};
export type TVisitation = {
  id: string;
  animalName: string;
  animalProfilePicture: string | null;
  species: TEditingField;
  breed?: TEditingField;
  primaryColor?: TEditingField;
  secondaryColor1?: TEditingField;
  secondaryColor2?: TEditingField;
  startDate: string;
  endDate: string;
  explanation: string;
  willBeLeftWithoutSupervision: boolean;
  precautions: string;
  didUserConfirmLearningEfforts: boolean;
  status: EVisitationStatus;
  isServiceAnimal: boolean;
  trainedFor: string;
  requiredBecauseOfDisability: boolean;
  authorization: EAuthorizationStatus;
  propertyManagement: TPropertyManagement;
  rejectReason: string | null;
  modifiable: boolean;
};

export type TAnimalFieldUpdateUI = {
  visitationId: string;
  value: string;
};
export type TAnimalCreatableFieldUpdateUi = {
  visitationId: string;
  value: TOptionTypeUi;
};

export type TAnimalNameUpdateUI = TAnimalFieldUpdateUI;
export type TAnimalNameUpdate = {
  animalVisitationId: string;
  name: string;
};

export type TAnimalPictureUpdateUI = TAnimalFieldUpdateUI;
export type TAnimalPictureUpdate = {
  animalVisitationId: string;
  profilePicture: string;
};

export type TSpeciesUpdateUI = TAnimalCreatableFieldUpdateUi;
export type TSpeciesUpdate = {
  animalVisitationId: string;
  specie: TOptionType;
};

export type TBreedUpdateUI = TAnimalCreatableFieldUpdateUi;
export type TBreedUpdate = {
  animalVisitationId: string;
  breed: TOptionType;
};

export type TPrimaryColorUpdateUI = TAnimalCreatableFieldUpdateUi;
export type TPrimaryColorUpdate = {
  animalVisitationId: string;
  primaryColor: TOptionType;
};

export type TSecondaryColor1UpdateUI = TAnimalFieldUpdateUI;
export type TSecondaryColor1Update = {
  animalVisitationId: string;
  secondaryColor1: string;
};

export type TSecondaryColor2UpdateUI = TAnimalFieldUpdateUI;
export type TSecondaryColor2Update = {
  animalVisitationId: string;
  secondaryColor2: string;
};

export type TStartEndDateUpdateUI = {
  visitationId: string;
  startDateTime: string;
  endDateTime: string;
};
export type TStartEndDateUpdate = {
  animalVisitationId: string;
  startDate: string;
  endDate: string;
};

export type TAnimalRequiredUpdateUI = {
  visitationId: string;
  value: TYesNoOptionType;
};
export type TAnimalRequiredUpdate = {
  animalVisitationId: string;
  requiredBecauseOfDisability?: boolean | null;
};

export type TTrainedForUpdateUI = TAnimalFieldUpdateUI;
export type TTrainedForUpdate = {
  animalVisitationId: string;
  trainedFor: string;
};

export type TExplanationUpdateUI = TAnimalFieldUpdateUI;
export type TExplanationUpdate = {
  animalVisitationId: string;
  explanation: string;
};

export type TWillBeLeftUpdateUI = {
  visitationId: string;
  willBeLeft: TYesNoOptionType;
  precautions: string | null;
};
export type TWillBeLeftUpdate = {
  animalVisitationId: string;
  willBeLeftWithoutSupervision?: boolean | null;
  precautions: string | null;
};

export type TPrecautionsUpdateUI = TAnimalFieldUpdateUI;
export type TPrecautionsUpdate = {
  animalVisitationId: string;
  precautions: string;
};
