import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EComplaintUrls } from 'data/urls/complaint.url';
import {
  TAddCommentUI,
  TComplaint,
  TComplaintUI,
} from 'data/types/complaint.types';
import {
  addCommentConvertor,
  getComplaintConvertor,
} from 'data/convertors/complaint.convertors';

export const complaintApi = authSplitApi('complaint', [
  'complaint',
]).injectEndpoints({
  endpoints: build => ({
    getComplaint: build.query<TComplaintUI, string>({
      query(id) {
        return {
          url: `${EComplaintUrls.COMPLAINT}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['complaint'],
      keepUnusedDataFor: 0.0001,
      transformResponse: (data: TComplaint) =>
        getComplaintConvertor.fromDb(data),
    }),
    addComment: build.mutation<void, TAddCommentUI>({
      query(data) {
        return {
          url: `${EComplaintUrls.COMPLAINT}/${EComplaintUrls.COMMENT}`,
          method: 'PATCH',
          body: addCommentConvertor.toDb(data),
        };
      },
      invalidatesTags: ['complaint'],
    }),
    updateViewdStatus: build.query<void, { complaintId: string }>({
      query(data) {
        return {
          url: `${EComplaintUrls.COMPLAINT}/${EComplaintUrls.VIEW}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetComplaintQuery,
  useAddCommentMutation,
  useUpdateViewdStatusQuery,
} = complaintApi;
