import { TFileDB, TFileUI, TOptionTypeUi } from './generalTypes';

export enum EComplaintTitleDB {
  UNAUTHORIZED_ANIMAL = 'UNAUTHORIZED_ANIMAL',
  DAMAGE = 'DAMAGE',
  NOISE_DISTURBANCE = 'NOISE_DISTURBANCE',
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  PET_RULE_VIOLATION = 'PET_RULE_VIOLATION',
  AGGRESSIVE_BEHAVIOR = 'AGGRESSIVE_BEHAVIOR',
  OTHER = 'OTHER',
}

export enum EComplaintTypeDB {
  COMPLAINT = 'COMPLAINT',
  VIOLATION = 'VIOLATION',
}

export enum EComplaintTagDB {
  COMPLAINT = 'COMPLAINT',
  NOTICE = 'NOTICE',
}

export enum EComplaintTypeUI {
  COMPLAINT = 'Complaint',
  VIOLATION = 'Violation',
}

export enum EComplaintTagUI {
  COMPLAINT = 'Complaint',
  NOTICE = 'Notice',
}

export enum EComplaintTitleUI {
  UNAUTHORIZED_ANIMAL = 'Unauthorized animal',
  DAMAGE = 'Damage',
  NOISE_DISTURBANCE = 'Noise disturbance',
  WASTE_MANAGEMENT = 'Waste management',
  PET_RULE_VIOLATION = 'Pet rule violation',
  AGGRESSIVE_BEHAVIOR = 'Aggressive behavior',
  OTHER = 'Other',
}

export type TCreator = {
  creatorName: string;
  creatorPhoto: string;
};

export type TViolator = {
  violatorPropertyName: string;
  violatorName: string | null;
  violatorUnitName: string | null;
};

export type TCommentUI = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  message: string;
};
export type TComment = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  massage: string;
};

export type TCommentDataUI = {
  commentsCount: number;
  commentsList: TCommentUI[];
};
export type TCommentData = {
  commentsCount: number;
  commentsList: TComment[];
};

export type TComplaintUI = {
  id: string;
  tag: EComplaintTagUI;
  createdAt: string;
  type: EComplaintTypeUI;
  title: EComplaintTitleUI;
  description: string;
  hasUnreadComment: boolean;
  attachments: TFileUI[];
  viewed: boolean;
  creator: TCreator;
  violator: TViolator;
  comments: TCommentDataUI;
  conversationWith: string;
};

export type TComplaint = {
  id: string;
  tag: EComplaintTagDB;
  reporterName: string;
  reporterPhoto: string;
  createdAt: string;
  violatorName: string;
  violatorPropertyName: string;
  violatorUnitName: string;
  type: EComplaintTypeDB;
  title: EComplaintTitleDB;
  description: string;
  commentsCount: number;
  hasUnreadComment: boolean;
  attachments: TFileDB[];
  comments: TComment[];
  viewed: boolean;
  conversationWith: string;
};

export type TAddAttachmentUI = {
  complaintId: string;
  attachments: TFileUI[];
};
export type TAddAttachment = {
  complaintId: string;
  attachments: TFileDB[];
};

export type TAddCommentUI = {
  complaintId: string;
  comment: string;
};
export type TAddComment = {
  complaintId: string;
  message: string;
};

export type TComplaintCreate = {
  title: EComplaintTitleDB;
  description: string;
  propertyId: string;
  unitName?: string;
  violatorName?: string;
  attachments: TFileDB[];
};

export type TComplaintCreateUI = {
  title: TOptionTypeUi;
  description: string;
  propertyId: TOptionTypeUi;
  unitName?: string;
  violatorName?: string;
  attachments: TFileUI[];
};
