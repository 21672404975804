import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  TPaymentDetailData,
  TPaymentDetailDataUI,
  TPaymentFormData,
  TPaymentFormDataUI,
} from 'data/types/payment.types';

export const addCreditCardConvertor: TCreateConverter<
  TPaymentFormDataUI,
  TPaymentFormData
> = {
  toDb: data => ({
    paymentMethodId: data.paymentMethodId,
  }),
};

export const paymentDataConverter: TFetchConverter<
  TPaymentDetailDataUI,
  TPaymentDetailData
> = {
  fromDb: data => {
    return {
      brand: data.brand,
      endingNumber: data.endingNumber,
      expMonth: data.expMonth,
      expYear: data.expYear,
      name: data.name,
    };
  },
};
