import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPaymentUrls } from 'data/urls/payment.url';
import {
  TPaymentDetailData,
  TPaymentDetailDataUI,
  TPaymentFormDataUI,
} from 'data/types/payment.types';
import {
  addCreditCardConvertor,
  paymentDataConverter,
} from 'data/convertors/payment.convertor';

export const paymentApi = authSplitApi('payment', [
  'creditCard',
]).injectEndpoints({
  endpoints: build => ({
    addPaymentMethod: build.mutation<void, TPaymentFormDataUI>({
      query(body) {
        return {
          url: `${EPaymentUrls.PAYMENT}`,
          method: 'PUT',
          body: addCreditCardConvertor.toDb(body),
        };
      },
      invalidatesTags: ['creditCard'],
    }),
    getPaymentData: build.query<TPaymentDetailDataUI, void>({
      query() {
        return {
          url: `${EPaymentUrls.PAYMENT}`,
          method: 'GET',
        };
      },
      providesTags: ['creditCard'],
      transformResponse: (data: TPaymentDetailData | null) => {
        if (!data) {
          return null;
        }
        return paymentDataConverter.fromDb(data);
      },
    }),
  }),
});

export const { useAddPaymentMethodMutation, useGetPaymentDataQuery } =
  paymentApi;
