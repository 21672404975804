export enum ETenantUrls {
  TENANT_PROFILE = 'profile',
  ONBOARD = 'onboard',
  DETAILS = 'details',
  PROFILE_PICTURE = 'profile-picture',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ANIMAL_METADATA = 'animal-metadata',
  ESA = 'esa',
  SA = 'sa',
  SHOW_QR_SCAN = 'show-qr-scan',
  PROPERTIES = 'properties',
  ROOMMATE_HAS_ANIMAL = 'roommate-animal-status',
  OWNER_HAS_ANIMAL = 'animal-status',
  ANIMALS = 'animals',
  REGISTER = 'register',
  DUPLICATIONS = 'duplications',
  ATTACH_TO = 'attach-to',
}
