import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EOnboardingUrls } from 'data/urls/onboarding.url';
import {
  TOnboardingAddAplicationUI,
  TOnboardingCompleteUI,
  TOnboardingCreateApplicationUI,
  TOnboardingPaymentDataDB,
  TOnboardingPaymentDataUI,
  TOnboardingConfirmPaymentUI,
  TOnboardingRemoveAplicationUI,
} from 'data/types/onboarding.types';
import {
  onboardingAddAplicationConvertor,
  onboardingCompleteConvertor,
  onboardingCreateApplicationConvertor,
  onboardingConfirmPaymentConvertor,
  onboardingPaymentDataConvertor,
  onboardingRemoveAplicationConvertor,
} from 'data/convertors/onboarding.convertors';
import {
  applyLeasePacketCodeConvertor,
  leasePacketDataConverter,
} from 'data/convertors/leasePacket.convertors';
import {
  TApplyLeasePacketCodeUI,
  TLeasePacketDB,
  TLeasePacketUI,
} from 'data/types/leasePacket.types';
import { TAnimalDuplicationDB } from 'data/types/animal.types';
import { incompleteAnimalsConverte } from 'data/convertors/application.converters';

const baseUrl = EOnboardingUrls.BASE;

export const onboardingApi = authSplitApi('onboarding', [
  'paymentStep',
]).injectEndpoints({
  endpoints: build => ({
    addAplication: build.mutation<
      TAnimalDuplicationDB,
      TOnboardingAddAplicationUI
    >({
      query(data) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.ANIMAL_TO_APPLY}`,
          method: 'PATCH',
          body: onboardingAddAplicationConvertor.toDb(data),
        };
      },
      transformResponse: (data: TAnimalDuplicationDB) =>
        incompleteAnimalsConverte.fromDb(data),
    }),
    removeAplication: build.mutation<void, TOnboardingRemoveAplicationUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.ANIMAL_TO_APPLY}`,
          method: 'DELETE',
          body: onboardingRemoveAplicationConvertor.toDb(data),
        };
      },
    }),
    completeOnboarding: build.mutation<void, TOnboardingCompleteUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.COMPLETE}`,
          method: 'PATCH',
          body: onboardingCompleteConvertor.toDb(data),
        };
      },
    }),

    onboardingCreateApplication: build.mutation<
      void,
      TOnboardingCreateApplicationUI
    >({
      query(data) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.CREATE_APPLICATION}`,
          method: 'POST',
          body: onboardingCreateApplicationConvertor.toDb(data),
        };
      },
    }),

    getPaymentStepData: build.query<
      TOnboardingPaymentDataUI,
      { tenantResidencyId: string; leasePacketId: string }
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.PAYMENT}`,
          method: 'GET',
          params: body,
        };
      },
      providesTags: ['paymentStep'],
      transformResponse: (data: TOnboardingPaymentDataDB) =>
        onboardingPaymentDataConvertor.fromDb(data),
    }),

    onboardingConfirmPayment: build.mutation<void, TOnboardingConfirmPaymentUI>(
      {
        query(data) {
          return {
            url: `${baseUrl}/${EOnboardingUrls.PAYMENT}`,
            method: 'POST',
            body: onboardingConfirmPaymentConvertor.toDb(data),
          };
        },
        invalidatesTags: ['paymentStep'],
      },
    ),
    applyLeasePacketCode: build.mutation<
      TLeasePacketUI,
      TApplyLeasePacketCodeUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.CODE}`,
          method: 'POST',
          body: applyLeasePacketCodeConvertor.toDb(body),
        };
      },
      transformResponse: (data: TLeasePacketDB) =>
        leasePacketDataConverter.fromDb(data),
      invalidatesTags: [],
    }),
    onboardingConfirmType: build.mutation<void, { tenantResidencyId: string }>({
      query(data) {
        return {
          url: `${baseUrl}/${EOnboardingUrls.TYPE_CONFIRM}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useAddAplicationMutation,
  useRemoveAplicationMutation,
  useCompleteOnboardingMutation,
  useGetPaymentStepDataQuery,
  useOnboardingCreateApplicationMutation,
  useOnboardingConfirmPaymentMutation,
  useApplyLeasePacketCodeMutation,
  useOnboardingConfirmTypeMutation,
} = onboardingApi;
