/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const amplifyConfig = {
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  aws_project_region: import.meta.env.REACT_APP_BUCKET_REGION,
  aws_cognito_identity_pool_id: import.meta.env.REACT_APP_COGNITO_IDENTITY_ID,
  aws_cognito_region: import.meta.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: import.meta.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: import.meta.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  aws_user_files_s3_bucket: import.meta.env.REACT_APP_BUCKET_NAME,
  aws_user_files_s3_bucket_region: import.meta.env.REACT_APP_BUCKET_REGION,
  oauth: {
    domain: import.meta.env.REACT_APP_COGNITO_DOMAIN,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: import.meta.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN_URL,
    redirectSignOut: import.meta.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT_URL,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default amplifyConfig;
