import { Outlet, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Screens } from 'core/CssVariables';
import { useGetIncompleteAnimalsQuery } from 'redux/apiSlice/animal.slice';
import KnowledgeBaseAction from 'components/Shared/KnowledgeBaseAction';
import { RoutesPaths } from 'routes/Routes.types';
import { SLayoutWrapper, SMainContentWrapper } from './Layout.styles';
import Footer from './Footer';
import Header from './Header';
import CompleteAnimalBar from './CompleteAnimalBar';

const Layout = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });
  const { data: incompleteAnimals, isLoading } = useGetIncompleteAnimalsQuery();
  if (isLoading) return null;

  const isKB = location.pathname.includes(RoutesPaths.KNOWLEDGE_BASE);

  return (
    <SLayoutWrapper>
      <KnowledgeBaseAction />

      <Header />
      {!isKB && <CompleteAnimalBar data={incompleteAnimals} />}
      <SMainContentWrapper
        className="container-lg"
        incompleteAnimal={!!incompleteAnimals?.count}
      >
        <Outlet />
      </SMainContentWrapper>
      {!isMobile && <Footer />}
    </SLayoutWrapper>
  );
};

export default Layout;
