import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EVisitationUrls } from 'data/urls/visitation.url';
import {
  animalNameUpdateConvertor,
  animalPictureUpdateConvertor,
  animalRequiredUpdateConvertor,
  breedUpdateConvertor,
  createVisitationConvertor,
  explanationUpdateConvertor,
  primaryColorUpdateConvertor,
  secondaryColor1UpdateConvertor,
  secondaryColor2UpdateConvertor,
  speciesUpdateConvertor,
  visitationConvertor,
  visitationStartEndDateUpdateConvertor,
  trainedForUpdateConvertor,
  willBeLeftUpdateConvertor,
  precautionsUpdateConvertor,
} from 'data/convertors/visitation.convertors';
import {
  TVisitation,
  TStartEndDateUpdateUI,
  TVisitationUI,
  TVisitationFormDataUI,
  TAnimalNameUpdateUI,
  TAnimalPictureUpdateUI,
  TAnimalRequiredUpdateUI,
  TBreedUpdateUI,
  TExplanationUpdateUI,
  TSpeciesUpdateUI,
  TPrimaryColorUpdateUI,
  TSecondaryColor1UpdateUI,
  TSecondaryColor2UpdateUI,
  TTrainedForUpdateUI,
  TWillBeLeftUpdateUI,
  TPrecautionsUpdateUI,
} from 'data/types/visitation.types';

const baseUrl = `${EVisitationUrls.VISITATIONS}`;

export const visitationApi = authSplitApi('visitation', [
  'visitations',
]).injectEndpoints({
  endpoints: build => ({
    createVisitation: build.mutation<void, TVisitationFormDataUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body: createVisitationConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    getVisitations: build.query<TVisitationUI[], void>({
      query() {
        return {
          url: `${baseUrl}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TVisitation[]) =>
        data.map(item => visitationConvertor.fromDb(item)),
      providesTags: ['visitations'],
    }),
    updateAnimalPicture: build.mutation<void, TAnimalPictureUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.PROFILE_PICTURE}`,
          method: 'PATCH',
          body: animalPictureUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateAnimalName: build.mutation<void, TAnimalNameUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.NAME}`,
          method: 'PATCH',
          body: animalNameUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateSpecies: build.mutation<void, TSpeciesUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.SPECIES}`,
          method: 'PATCH',
          body: speciesUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateBreed: build.mutation<void, TBreedUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.BREED}`,
          method: 'PATCH',
          body: breedUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updatePrimaryColor: build.mutation<void, TPrimaryColorUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.PRIMARY_COLOR}`,
          method: 'PATCH',
          body: primaryColorUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateSecondaryColor1: build.mutation<void, TSecondaryColor1UpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.SECONDARY_COLOR_1}`,
          method: 'PATCH',
          body: secondaryColor1UpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateSecondaryColor2: build.mutation<void, TSecondaryColor2UpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.SECONDARY_COLOR_2}`,
          method: 'PATCH',
          body: secondaryColor2UpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateVisitationDate: build.mutation<void, TStartEndDateUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.DATE_RANGE}`,
          method: 'PATCH',
          body: visitationStartEndDateUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateAnimalRequired: build.mutation<void, TAnimalRequiredUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.REQUIRED}`,
          method: 'PATCH',
          body: animalRequiredUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateTrainedFor: build.mutation<void, TTrainedForUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.TRAINED_FOR}`,
          method: 'PATCH',
          body: trainedForUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateExplanation: build.mutation<void, TExplanationUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.EXPLANATION}`,
          method: 'PATCH',
          body: explanationUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updateWillBeLeft: build.mutation<void, TWillBeLeftUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.WILL_BE_LEFT}`,
          method: 'PATCH',
          body: willBeLeftUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
    updatePrecautions: build.mutation<void, TPrecautionsUpdateUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EVisitationUrls.PRECAUTIONS}`,
          method: 'PATCH',
          body: precautionsUpdateConvertor.toDb(body),
        };
      },
      invalidatesTags: ['visitations'],
    }),
  }),
});

export const {
  useGetVisitationsQuery,
  useCreateVisitationMutation,
  useUpdateAnimalPictureMutation,
  useUpdateVisitationDateMutation,
  useUpdateAnimalNameMutation,
  useUpdateAnimalRequiredMutation,
  useUpdateSpeciesMutation,
  useUpdateBreedMutation,
  useUpdateExplanationMutation,
  useUpdatePrimaryColorMutation,
  useUpdateSecondaryColor1Mutation,
  useUpdateSecondaryColor2Mutation,
  useUpdateTrainedForMutation,
  useUpdateWillBeLeftMutation,
  useUpdatePrecautionsMutation,
} = visitationApi;
