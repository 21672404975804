import {
  menuPathItems,
  menuActionItems,
} from 'components/Base/Layout/Header/Header.data';
import { TCollapsedMenuItemsProps } from 'components/Base/Layout/Header/Header.props';
import MenuPathItem from '../../../MenuItem/MenuPathItem';
import MenuActionItem from '../../../MenuItem/MenuActionItem';
import { SMenuWrapper } from '../CollapsedMenu.styles';

const CollapsedMenuSecondary = ({
  onClick = () => {
    /* do nothing */
  },
  handleToggle = () => {
    /* do nothing */
  },
}: TCollapsedMenuItemsProps) => {
  return (
    <SMenuWrapper>
      {menuPathItems.map(route => (
        <MenuPathItem
          key={route.path}
          handleToggle={handleToggle}
          menuItem={route}
        />
      ))}
      {menuActionItems.map(route => (
        <MenuActionItem
          key={route.actionType}
          onClick={onClick}
          menuItem={route}
        />
      ))}
    </SMenuWrapper>
  );
};

export default CollapsedMenuSecondary;
