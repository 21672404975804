import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import React, { Suspense, useEffect, FC } from 'react';
import Layout from 'components/Base/Layout/Layout';
import appSlice from 'redux/slices/app.slice';
import { privateRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';
import { useAppDispatch, useAppSelector } from '../redux/store';

export const PrivateRoutes: FC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectLink = useAppSelector(state => state.appSlice.redirectLink);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/', { replace: true });
    } else if (redirectLink) {
      navigate(redirectLink);
      dispatch(appSlice.actions.setRedirectLink(''));
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) return null;
  return (
    <Suspense fallback={<Layout />}>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="*"
            element={<Navigate replace to={RoutesPaths.PAGE_NOT_FOUND} />}
          />
          {privateRoutes.map(({ path, component }) => {
            const Component = component;
            // const pathStr = subRoutes?.length ? `${path}/*` : `${path}`;
            return (
              <Route key={path} path={`${path}/*`} element={<Component />} />
            );
          })}
        </Route>
      </Routes>
    </Suspense>
  );
};
