import styled from 'styled-components';
import { Image } from '@landlord-tech/ui-kit-opp/dist/components/Image';
import { TImage } from './Image.types';

const SImage = styled(Image)<TImage>`
  &&& {
  }
`;

export default SImage;
