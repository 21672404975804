import styled from 'styled-components';
import {
  Title,
  Text,
  SecondaryText,
  Link,
  Paragraph,
} from '@landlord-tech/ui-kit-opp/dist/components/Typography';

export const STitle = styled(Title)`
  &&& {
  }
`;

export const SText = styled(Text)`
  &&& {
  }
`;

export const SSecondaryText = styled(SecondaryText)`
  &&& {
  }
`;

export const SLink = styled(Link)`
  &&& {
  }
`;

export const SParagraph = styled(Paragraph)`
  &&& {
  }
`;
