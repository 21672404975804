import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EResidencyUrls } from 'data/urls/residency.url';
import {
  TChangeResidencyFormDataUI,
  TCompleteResidencyApplicationUI,
  TCompleteTenantResidencyUI,
  TCreateTenantResidencyUI,
  TResidencyAnimalCountUI,
  TResidencyApplicationDataDB,
  TResidencyApplicationDataUI,
  TResidencyData,
  TResidencyDataUI,
  TValidateResidencyUnitUI,
} from 'data/types/residency.types';
import {
  residencyAnimalCountConverter,
  residencyApplicationDataConverter,
  residencyDataConverter,
  tenantResidencyApplicationCreateConverter,
  tenantResidencyCompleteConverter,
  tenantResidencyCreateConverter,
  updateResidencyDataConverter,
} from 'data/convertors/residency.convertor';
import { TMoveOutFromResidencyFormUI } from 'data/types/animal.types';
import { EAnimalUrls } from 'data/urls/animal.url';
import { animalMoveOutFromResidenciesConvertor } from 'data/convertors/animal.convertors';

const baseUrl = `${EResidencyUrls.RESIDENCY}`;

export const residencyApi = authSplitApi('residency', [
  'residency',
  'inCompleteResidency',
  'inCompleteResidencyApplication',
]).injectEndpoints({
  endpoints: build => ({
    getResidencyData: build.query<TResidencyDataUI[], null | void>({
      query() {
        return {
          url: baseUrl,
          method: 'GET',
        };
      },
      providesTags: ['residency'],
      transformResponse: (data: TResidencyData[]) =>
        data.map(item => residencyDataConverter.fromDb(item)),
    }),
    getIncompleteResidencyData: build.query<TResidencyDataUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EResidencyUrls.INCOMPLETE}`,
          method: 'GET',
        };
      },
      providesTags: ['inCompleteResidency'],
      transformResponse: (data: TResidencyData[]) =>
        // TODO: sort by priority of incompleted onboarding state
        data.map(item => residencyDataConverter.fromDb(item)),
    }),
    createTenantResidency: build.mutation<string, TCreateTenantResidencyUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.APPLICATION}`,
          method: 'POST',
          body: tenantResidencyCreateConverter.toDb(data),
        };
      },
    }),
    completeTenantResidency: build.mutation<void, TCompleteTenantResidencyUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.COMPLETE}`,
          method: 'PATCH',
          body: tenantResidencyCompleteConverter.toDb(data),
        };
      },
    }),
    moveOutAnimalsFromResidency: build.mutation<
      void,
      TMoveOutFromResidencyFormUI
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EAnimalUrls.ANIMALS}/${EAnimalUrls.MOVE_OUT}`,
          method: 'PATCH',
          body: animalMoveOutFromResidenciesConvertor.toDb(body),
        };
      },
      invalidatesTags: ['inCompleteResidency'],
    }),

    validateUnitNumber: build.mutation<string, TValidateResidencyUnitUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.APPLICATION}/${data.residencyApplicationId}/${EResidencyUrls.UNIT}/${EResidencyUrls.VERIFY}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    updateResidencyData: build.mutation<void, TChangeResidencyFormDataUI>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'PUT',
          body: updateResidencyDataConverter.toDb(body),
        };
      },
      // invalidatesTags: ['residency'], check if needed
    }),
    getIncompleteResidencyApplicationData: build.query<
      TResidencyApplicationDataUI[],
      void
    >({
      query() {
        return {
          url: `${baseUrl}/${EResidencyUrls.APPLICATION}/${EResidencyUrls.INCOMPLETE}`,
          method: 'GET',
        };
      },
      providesTags: ['inCompleteResidencyApplication'],
      transformResponse: (data: TResidencyApplicationDataDB[]) =>
        data.map(item => residencyApplicationDataConverter.fromDb(item)),
    }),
    completeResidencyApplication: build.mutation<
      void,
      TCompleteResidencyApplicationUI
    >({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.APPLICATION}/${EResidencyUrls.COMPLETE}`,
          method: 'POST',
          body: tenantResidencyApplicationCreateConverter.toDb(data),
        };
      },
    }),
    setResidencyFirstFaceDate: build.mutation<
      void,
      { tenantResidencyId: string }
    >({
      query(body) {
        return {
          url: `${baseUrl}/${EResidencyUrls.FIRST_TIME_FACE}`,
          method: 'PATCH',
          body,
        };
      },
    }),

    residancyAnimalCount: build.mutation<void, TResidencyAnimalCountUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EResidencyUrls.ANIMAL_COUNT}`,
          method: 'PATCH',
          body: residencyAnimalCountConverter.toDb(data),
        };
      },
      invalidatesTags: ['inCompleteResidencyApplication'],
    }),
  }),
});

export const {
  useGetResidencyDataQuery,
  useCreateTenantResidencyMutation,
  useCompleteTenantResidencyMutation,
  useGetIncompleteResidencyDataQuery,
  useMoveOutAnimalsFromResidencyMutation,
  useValidateUnitNumberMutation,
  useUpdateResidencyDataMutation,
  useGetIncompleteResidencyApplicationDataQuery,
  useCompleteResidencyApplicationMutation,
  useSetResidencyFirstFaceDateMutation,
  useResidancyAnimalCountMutation,
} = residencyApi;
