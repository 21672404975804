import React, { ReactNode } from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { oppLocalStorage, TStorageNames } from 'helpers/storage';

const bugsnagKey = '98bdb88f72538d37eaf1b480435b43c4';
export const bugsnagClient =
  import.meta.env.REACT_APP_ENVIRONMENT === 'prod'
    ? Bugsnag.start({
        apiKey: bugsnagKey,
        plugins: [new BugsnagPluginReact()],
      })
    : null;

BugsnagPerformance.start({ apiKey: bugsnagKey });
type Props = {
  children?: ReactNode;
};

class ErrorBoundary extends React.Component<Props> {
  public componentDidCatch(error: Error) {
    const user = oppLocalStorage.getItem(TStorageNames.user);
    if (bugsnagClient) {
      if (user) {
        bugsnagClient.setUser(user?.username, user?.username, user?.name);
      }
      if (window.location.pathname.includes('social-auth')) {
        bugsnagClient.setContext('google');
      }
      bugsnagClient.addMetadata('metadata', error);
      bugsnagClient.notify(error);
    }
    if (error?.name?.includes('ChunkLoadError')) {
      window.location.reload();
    }
  }

  public render() {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
