import styled from 'styled-components';
import { Divider } from '@landlord-tech/ui-kit-opp/dist/components/Divider';
import { TDividerProps } from './Divider.types';

const SDivider = styled(Divider)<TDividerProps>`
  &&& {
  }
`;

export default SDivider;
