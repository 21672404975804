import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EAnimalTrainingUrls } from 'data/urls/animalTraining.url';
import {
  TSADocPacketCreateUI,
  TSADocPacketGet,
  TSADocPacketGetUI,
  TSALetterUpdateUI,
  TSAPacketDeleteParams,
  TNameUpdateUI,
  TEmailUpdateUI,
  TPhoneUpdateUI,
  TFaxUpdateUI,
  TTrainedForUpdateUI,
  TTrainingStartDateUpdateUI,
  TTrainingEndDateUpdateUI,
} from 'data/types/animalTraining.types';
import {
  createSADocPacketsConvertor,
  getSADocPacketConvertor,
  saLetterUpdateConvertor,
  trainedForUpdateConvertor,
  trainerEmailUpdateConvertor,
  trainerFaxUpdateConvertor,
  trainerNameUpdateConvertor,
  trainerPhoneUpdateConvertor,
  trainingEndDateUpdateConvertor,
  trainingStartDateUpdateConvertor,
} from 'data/convertors/animalTraining.convertors';

const baseUrl = `${EAnimalTrainingUrls.ANIMALS}/${EAnimalTrainingUrls.SA_DOCUMENTS}`;

export const animalTrainingApi = authSplitApi('animalTraining', [
  'saDocPacket',
  'saDocPackets',
]).injectEndpoints({
  endpoints: build => ({
    getSADocPackets: build.query<TSADocPacketGetUI[], string>({
      query(animalId) {
        return {
          url: baseUrl,
          method: 'GET',
          params: { animalId },
        };
      },
      transformResponse: (data: TSADocPacketGet[]) =>
        data.map(item => getSADocPacketConvertor.fromDb(item)),
      providesTags: ['saDocPackets'],
    }),
    createSADocPacket: build.mutation<void, TSADocPacketCreateUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'POST',
          body: createSADocPacketsConvertor.toDb(body),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    deleteSADocPacket: build.mutation<void, TSAPacketDeleteParams>({
      query({ animalId, saDocumentId }) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body: { animalId, saDocumentId },
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateSALetters: build.mutation<void, TSALetterUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.SA_LETTERS}`,
          method: 'PATCH',
          body: saLetterUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainerName: build.mutation<void, TNameUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.NAME}`,
          method: 'PATCH',
          body: trainerNameUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainerEmail: build.mutation<void, TEmailUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.EMAIL}`,
          method: 'PATCH',
          body: trainerEmailUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainerPhone: build.mutation<void, TPhoneUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.PHONE}`,
          method: 'PATCH',
          body: trainerPhoneUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainerFax: build.mutation<void, TFaxUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.FAX}`,
          method: 'PATCH',
          body: trainerFaxUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainedFor: build.mutation<void, TTrainedForUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINED_FOR}`,
          method: 'PATCH',
          body: trainedForUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainingStartDate: build.mutation<void, TTrainingStartDateUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINING_START}`,
          method: 'PATCH',
          body: trainingStartDateUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
    updateTrainingEndDate: build.mutation<void, TTrainingEndDateUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINING_END}`,
          method: 'PATCH',
          body: trainingEndDateUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['saDocPackets'],
    }),
  }),
});

export const {
  useGetSADocPacketsQuery,
  useCreateSADocPacketMutation,
  useDeleteSADocPacketMutation,
  useUpdateSALettersMutation,
  useUpdateTrainerNameMutation,
  useUpdateTrainerEmailMutation,
  useUpdateTrainerPhoneMutation,
  useUpdateTrainerFaxMutation,
  useUpdateTrainedForMutation,
  useUpdateTrainingStartDateMutation,
  useUpdateTrainingEndDateMutation,
} = animalTrainingApi;
