import {
  TAuthConverter,
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  TDocPacketUI,
  TDocPacket,
  THPVisitation,
  THPVisitationUI,
  THPContactInfoUI,
  THPContactInfo,
  TNameUpdateUI,
  TNameUpdate,
  TEmailUpdateUI,
  TEmailUpdate,
  TPhoneUpdateUI,
  TPhoneUpdate,
  TFaxUpdateUI,
  TFaxUpdate,
  THPVisitationUpdateUI,
  THPVisitationUpdate,
  EHPDocPacketTypes,
  EHPDocPacketTypesUI,
  EHPVisitation,
  EHPVisitationUI,
  TDocPacketGetUI,
  TDocPacketGet,
  THPLetterUpdateUI,
  THPLetterUpdate,
  ETemplateTypeUI,
  ETemplateType,
  TDocPacketInfoUI,
  TDocPacketInfoDB,
} from 'data/types/docPacket.types';
import {
  phoneNumberConverter,
  verificationStatusConverter,
} from './general.converters';

export const hpDocTypeConvertor: TConvertor<
  EHPDocPacketTypesUI,
  EHPDocPacketTypes
> = {
  fromDb: data => {
    const obj = {
      [EHPDocPacketTypes.ESA]: EHPDocPacketTypesUI.ESA,
      [EHPDocPacketTypes.SA]: EHPDocPacketTypesUI.SA,
    };
    return obj[data];
  },
  toDb: data => {
    const obj = {
      [EHPDocPacketTypesUI.ESA]: EHPDocPacketTypes.ESA,
      [EHPDocPacketTypesUI.SA]: EHPDocPacketTypes.SA,
    };
    return obj[data];
  },
};

export const hpVisitationTypeConvertor: TConvertor<
  EHPVisitationUI,
  EHPVisitation
> = {
  fromDb: data => {
    return EHPVisitationUI[EHPVisitation[data] as keyof typeof EHPVisitationUI];
  },
  toDb: data => {
    return EHPVisitation[EHPVisitationUI[data] as keyof typeof EHPVisitation];
  },
};

export const templateTypeConvertor: TConvertor<ETemplateTypeUI, ETemplateType> =
  {
    fromDb: data => {
      return ETemplateTypeUI[
        ETemplateType[data] as keyof typeof ETemplateTypeUI
      ];
    },
    toDb: data => {
      return ETemplateType[ETemplateTypeUI[data] as keyof typeof ETemplateType];
    },
  };

export const hpVisitationConvertor: TCreateConverter<
  THPVisitationUI,
  THPVisitation
> = {
  toDb: data => {
    return {
      websiteAdvertised: data.websiteAdvertised === 'yes',
      websites: data.websites
        ? [data.websites]?.filter(website => !!website)
        : undefined,
    };
  },
};

export const hpContactInfoConvertor: TAuthConverter<
  THPContactInfoUI,
  THPContactInfo,
  THPContactInfoUI,
  THPContactInfo
> = {
  toDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress?.trim() || undefined,
    phoneNumber: phoneNumberConverter.toDb(data.phoneNumber) || undefined,
    faxNumber: data.faxNumber?.trim() || undefined,
  }),
  fromDb: data => ({
    name: data.name,
    emailAddress: data.emailAddress,
    phoneNumber: data.phoneNumber,
    faxNumber: data.faxNumber,
  }),
};

export const hpNameUpdateConvertor: TCreateConverter<
  TNameUpdateUI,
  TNameUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactName: data.value || null,
  }),
};

export const hpEmailUpdateConvertor: TCreateConverter<
  TEmailUpdateUI,
  TEmailUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactEmail: data.value || null,
  }),
};

export const hpPhoneUpdateConvertor: TCreateConverter<
  TPhoneUpdateUI,
  TPhoneUpdate
> = {
  toDb: data => ({
    documentPacketId: data.documentPacketId,
    hpContactPhone: phoneNumberConverter.toDb(data.value),
  }),
};

export const hpFaxUpdateConvertor: TCreateConverter<TFaxUpdateUI, TFaxUpdate> =
  {
    toDb: data => ({
      documentPacketId: data.documentPacketId,
      hpContactFaxNumber: data.value || null,
    }),
  };

export const hpVisitationUpdateConvertor: TCreateConverter<
  THPVisitationUpdateUI,
  THPVisitationUpdate
> = {
  toDb: data => {
    return {
      documentPacketId: data.documentPacketId,
      websiteAdvertised: hpVisitationConvertor.toDb(data.websiteAdvertised),
    };
  },
};

export const hpLetterUpdateConvertor: TCreateConverter<
  THPLetterUpdateUI,
  THPLetterUpdate
> = {
  toDb: data => ({
    documentPacketId: data.hpDocumentPacketId,
    newHpLetters: data.newHpLetters || [],
    removedHpLetterIds: data.removedDocumentsIds || [],
  }),
};

export const getHPDocPacketsConvertor: TFetchConverter<
  TDocPacketGetUI,
  TDocPacketGet
> = {
  fromDb: data => {
    return {
      hpDocumentPacketId: data.hpDocumentPacketId,
      type: hpDocTypeConvertor.fromDb(data.type),
      hpContactInfo: hpContactInfoConvertor.fromDb(data.hpContactInfo),
      websites: data.hpVisitation?.websites?.map(item => item && item.value),
      hpVisitation: data.hpVisitation?.websiteAdvertised,
      hpDocForms: data.hpDocForms,
      hpDocLetters: data.hpDocLetters,
      verificationStatus: data.verificationStatus,
      canEdit: data.modifiable,
      status: verificationStatusConverter.fromDb(data.verificationStatus),
      receivedAfterCompletingQuestionnaireOrInterview:
        data.receivedAfterCompletingQuestionnaireOrInterview,
    };
  },
};

export const createHPDocConvertor: TCreateConverter<TDocPacketUI, TDocPacket> =
  {
    toDb: data => ({
      hpContactInfo: hpContactInfoConvertor.toDb({
        name: data.name,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        faxNumber: data.faxNumber,
      }),
      hpDocType: data.hpDocType
        ? hpDocTypeConvertor.toDb(data.hpDocType)
        : undefined,
      hpVisitation: data.websiteAdvertised
        ? hpVisitationConvertor.toDb({
            websiteAdvertised: data.websiteAdvertised,
            websites: data.websiteAdvertised,
          })
        : undefined,
      hpDocPacketLetter: data.hpDocPacketLetter,
      receivedAfterCompletingQuestionnaireOrInterview: null,
    }),
  };

export const createHPDocInfoConvertor: TCreateConverter<
  TDocPacketInfoUI,
  TDocPacketInfoDB
> = {
  toDb: data => {
    return {
      hpDocumentPacketId: data.hpDocumentPacketId,
      receivedAfterCompletingQuestionnaireOrInterview:
        data.receivedAfterCompletingQuestionnaireOrInterview === 'yes',
      hpVisitation: {
        websiteAdvertised: data.websiteAdvertised === 'yes',
        websites: data.websites ? [data.websites] : undefined,
      },
    };
  },
};
