import styled from 'styled-components';
import { Header } from '@landlord-tech/ui-kit-opp/dist/components/Layout';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';

export const SHeaderWrapper = styled(Header)`
  position: fixed;
  width: 100%;
  background-color: ${Colors.White};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.13);
  z-index: 9;
  svg {
    height: 40px;
  }
  .ant-menu {
    &.ant-menu-horizontal {
      .ant-menu-item {
        &::after {
          border-bottom: none;
        }
        &:hover,
        &:has(a.active) {
          &::after {
            border-bottom: 3px solid ${Colors.PrimaryColor};
          }
        }
        a {
          color: ${Colors.Black};
          font-weight: ${FontWeights.Bold};
          font-size: ${FontSizes.FontMD}px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
`;
