import { TCollapsedMenuItemsProps } from 'components/Base/Layout/Header/Header.props';
import useFilterRoutes from 'hooks/useFilterRoutes';
import { PrivateRouteType } from 'routes/Routes.types';
import { SMenuWrapper } from '../CollapsedMenu.styles';
import CollapsedMenuPathItem from '../../../MenuItem/MenuPathItem';

const CollapsedMenuPrimary = ({
  handleToggle = () => {
    /* do nothing */
  },
}: TCollapsedMenuItemsProps) => {
  const routes = useFilterRoutes();

  const menuItems: PrivateRouteType[] = routes.filter(item => item.show);
  return (
    <SMenuWrapper>
      {menuItems.map(route => (
        <CollapsedMenuPathItem
          key={route.path}
          handleToggle={handleToggle}
          menuItem={route}
        />
      ))}
    </SMenuWrapper>
  );
};

export default CollapsedMenuPrimary;
