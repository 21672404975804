import { TFile } from 'data/types/storage.types';
import { EStorageUrls } from 'data/urls/storage.url';

import { authSplitApi } from '../helpers/slice.helpers';

export const storageApi = authSplitApi('storage', ['files']).injectEndpoints({
  endpoints: build => ({
    uploadFile: build.mutation<TFile, FormData>({
      query(body) {
        return {
          url: `${EStorageUrls.STORAGE}/${EStorageUrls.FILES}`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUploadFileMutation } = storageApi;
