import { useMediaQuery } from 'react-responsive';
import { Screens } from 'core/CssVariables';
import Button from 'components/Base/Button';
import { Col, Row } from 'components/Base/Grid';
import { Text, Title } from 'components/Base/Typography';
import { useState } from 'react';
import { TIconNames } from 'components/Base/Icon';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutesPaths } from 'routes/Routes.types';
import { SButton, SIcon, SPopover } from './KnowledgeBaseAction.styles';

const KnowledgeBaseAction = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });
  const isXXL = useMediaQuery({
    query: `(max-width: ${Screens.ScreensXXL})`,
  });
  const notVisiblePaths = [RoutesPaths.KNOWLEDGE_BASE, RoutesPaths.APPLICATION];
  const isNotVisible = notVisiblePaths.some(path =>
    location.pathname.includes(path),
  );

  if (isNotVisible) {
    return null;
  }

  return (
    <SPopover
      visible={show}
      trigger="click"
      placement="topRight"
      showArrow={false}
      content={
        <>
          <Row
            style={{
              width: 322,
              padding: 32,
            }}
            gutter={[16, 16]}
          >
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title>Need Help?</Title>
                </Col>
                <Col>
                  <SIcon
                    icon={TIconNames.CLOSE_CIRCLE}
                    onClick={() => setShow(false)}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Text>
                &quot;Looking for help? Visit the Knowledge Base to explore how
                to use our OurPetPolicy application.&quot;
              </Text>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  setShow(false);
                  navigate(RoutesPaths.KNOWLEDGE_BASE);
                }}
              >
                Explore Knowledge Base
              </Button>
            </Col>
          </Row>
        </>
      }
    >
      <SButton
        type="primary"
        shape="circle"
        onClick={() => setShow(true)}
        isMobile={isMobile}
        style={{
          bottom: isXXL ? '190px' : '96px',
        }}
      >
        ?
      </SButton>
    </SPopover>
  );
};

export default KnowledgeBaseAction;
