import { TFile } from './storage.types';
import {
  EVerificationStatus,
  EVerificationStatusUi,
  TFileDataType,
} from './generalTypes';

export enum EHPVisitationUI {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}
export enum EHPVisitation {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}

export enum EHPDocPacketTypesUI {
  SA = 'Service animal',
  ESA = 'ESA',
}
export enum EHPDocPacketTypes {
  SA = 'SA',
  ESA = 'ESA',
}

export enum ETemplateTypeUI {
  MENTAL_HEALTH_PROFESSIONAL = 'MENTAL_HEALTH_PROFESSIONAL',
  SERVICES_TRAINED = 'SERVICES_TRAINED',
}
export enum ETemplateType {
  MENTAL_HEALTH_PROFESSIONAL = 'MENTAL_HEALTH_PROFESSIONAL',
  SERVICES_TRAINED = 'SERVICES_TRAINED',
}

export type THPDocFieldUpdateUI = {
  documentPacketId: string;
  value: string;
};

export type THPVisitationUI = {
  websiteAdvertised: string;
  websites?: string;
};
export type THPVisitation = { websiteAdvertised: boolean; websites?: string[] };

export type THPVisitationGetUI = THPVisitationUI;
export type THPVisitationGet = {
  websiteAdvertised: boolean;
  websites?: { value: string }[];
};

export type THPContactInfoUI = {
  name?: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
};
export type THPContactInfo = THPContactInfoUI;
export type THPDocInfo = {
  reason: string | null;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
  hpContactInfo?: THPContactInfo;
  receivedAfterCompletingQuestionnaireOrInterview?: boolean;
};
export type TNameUpdateUI = THPDocFieldUpdateUI;
export type TNameUpdate = {
  documentPacketId: string;
  hpContactName: string | null;
};

export type TEmailUpdateUI = THPDocFieldUpdateUI;
export type TEmailUpdate = {
  documentPacketId: string;
  hpContactEmail: string | null;
};

export type TPhoneUpdateUI = THPDocFieldUpdateUI;
export type TPhoneUpdate = {
  documentPacketId: string;
  hpContactPhone: string | null;
};

export type TFaxUpdateUI = THPDocFieldUpdateUI;
export type TFaxUpdate = {
  documentPacketId: string;
  hpContactFaxNumber: string | null;
};

export type THPVisitationUpdateUI = {
  documentPacketId: string;
  websiteAdvertised: THPVisitationUI;
};
export type THPVisitationUpdate = {
  documentPacketId: string;
  websiteAdvertised: THPVisitation;
};

export type THPLetterUpdateUI = {
  hpDocumentPacketId: string;
  newHpLetters: TFile[];
  removedDocumentsIds?: string[];
};
export type THPLetterUpdate = {
  documentPacketId: string;
  newHpLetters: TFile[];
  removedHpLetterIds?: string[];
};

export type TDocPacketUI = {
  hpDocType?: EHPDocPacketTypesUI;
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string;
  websiteAdvertised: string;
  receivedAfterCompletingQuestionnaireOrInterview: string;
  hpDocPacketLetter?: TFile[];
};
export type TDocPacket = {
  hpDocType?: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFile[];
};

export type TDocPacketInfoDB = {
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
  hpVisitation: { websiteAdvertised: boolean; websites?: string[] };
};
export type TDocPacketInfoUI = {
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: string;
  websiteAdvertised: string;
  websites: string;
};
export type TDocPacketGetUI = {
  type: EHPDocPacketTypesUI;
  hpContactInfo: THPContactInfoUI;
  hpDocForms?: TFileDataType[];
  hpVisitation: boolean;
  websites?: string[];
  hpDocLetters?: TFileDataType[];
  canEdit: boolean;
  status: EVerificationStatusUi;
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
};
export type TDocPacketGet = {
  type: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpDocForms?: TFileDataType[];
  hpVisitation: THPVisitationGet;
  hpDocLetters?: TFileDataType[];
  modifiable: boolean;
  verificationStatus: EVerificationStatus;
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
};
