import { TFetchConverter } from 'data/types/convertor.types';
import {
  EPropertyTypes,
  EPropertyTypesUI,
  ESpeciesTypes,
  ESpeciesTypesUI,
  TModule,
  TModuleUI,
  TTrainingDetails,
  TTrainingDetailsUI,
} from 'data/types/curriculum.types';

export const propertyTypesConvertor: TFetchConverter<
  EPropertyTypesUI,
  EPropertyTypes
> = {
  fromDb: data => {
    const propertyTypes: {
      [key in EPropertyTypes]: EPropertyTypesUI;
    } = {
      [EPropertyTypes.SINGLE]: EPropertyTypesUI.SINGLE,
      [EPropertyTypes.MULTI]: EPropertyTypesUI.MULTI,
    };
    return propertyTypes[data];
  },
};

export const speciesTypesConvertor: TFetchConverter<
  ESpeciesTypesUI,
  ESpeciesTypes
> = {
  fromDb: data => {
    const speciesTypes: {
      [key in ESpeciesTypes]: ESpeciesTypesUI;
    } = {
      [ESpeciesTypes.DOG]: ESpeciesTypesUI.DOG,
      [ESpeciesTypes.CAT]: ESpeciesTypesUI.CAT,
    };
    return speciesTypes[data];
  },
};

export const moduleConvertor: TFetchConverter<TModuleUI, TModule> = {
  fromDb: data => ({
    title: data.title,
    order: data.order,
    duration: data.duration,
    passed: data.passed,
    photo: data.photo,
    video: data.video,
  }),
};

export const trainingConvertor: TFetchConverter<
  TTrainingDetailsUI,
  TTrainingDetails
> = {
  fromDb: data => ({
    id: data.id,
    name: data.name,
    duration: data.duration,
    modulesCount: data.modulesCount,
    photo: data.photo,
    passed: data.passed,
    required: data.required,
    propertyTypes: data.propertyTypes.map(item =>
      propertyTypesConvertor.fromDb(item),
    ),
    speciesTypes: data.speciesTypes.map(item =>
      speciesTypesConvertor.fromDb(item),
    ),
    modules: (data.subCourses || []).map(module =>
      moduleConvertor.fromDb(module),
    ),
  }),
};
