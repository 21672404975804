export enum EAnimalTrainingUrls {
  ANIMALS = 'animals',
  SA_DOCUMENTS = 'sa-documents',
  TRAINING_START = 'training-start',
  TRAINING_END = 'training-end',
  TRAINER_INFO = 'trainer-info',
  NAME = 'name',
  PHONE = 'phone',
  EMAIL = 'email',
  FAX = 'fax',
  TRAINED_FOR = 'trained-for',
  SA_LETTERS = 'sa-letters',
}
