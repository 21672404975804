import cloneDeep from 'lodash/cloneDeep';
import { TAuthConverter } from '../types/convertor.types';
import {
  TSignInAmplifyDataToDb,
  IAmplifySignUpUserData,
  TForgotPasswordAmplifyWithPhoneNumberProp,
  TForgotPasswordUserToDb,
  TSignInWithPhoneNumberAmplifyDataUI,
  TAmplifyWithPhoneNumberUser,
  TAmplifySignInWithPhoneNumberUserData,
  TSignUpWithPhoneNumberAmplifyDataUI,
  TSignUpWithPhoneNumberAmplifyDataToDb,
  TSignUpWithPhoneNumberAmplifyDataUI2,
} from '../types/amplify.types';

export const signUpWithPhoneNumberAmplifyConvertor: TAuthConverter<
  TSignUpWithPhoneNumberAmplifyDataUI,
  TSignUpWithPhoneNumberAmplifyDataToDb,
  TSignUpWithPhoneNumberAmplifyDataUI2,
  IAmplifySignUpUserData
> = {
  toDb: data => ({
    phoneNumber: `+${data.phoneNumber}`,
    password: data.password,
  }),
  fromDb: data => ({
    username: data?.user.username,
    password: data.password,
    userConfirmed: !!data?.userConfirmed,
    codeSend: true,
  }),
};

export const signInWithPhoneNumberAmplifyConvertor: TAuthConverter<
  TSignInWithPhoneNumberAmplifyDataUI,
  TSignInAmplifyDataToDb,
  TAmplifyWithPhoneNumberUser,
  TAmplifySignInWithPhoneNumberUserData
> = {
  fromDb: data => {
    const cognitoUser = cloneDeep(data);
    cognitoUser.username = data.username;
    cognitoUser.name = data.attributes?.name;
    cognitoUser.phoneNumber = data.attributes?.phone_number;
    cognitoUser.phoneNumberVerified = data.attributes?.phone_number_verified;

    return cognitoUser;
  },
  toDb: data => ({
    username:
      data.phoneNumber[0] === '+' ? data.phoneNumber : `+${data.phoneNumber}`,
    password: data.password,
  }),
};

export const forgotPasswordWithPhoneNumberAmplifyConvertor: TAuthConverter<
  TForgotPasswordAmplifyWithPhoneNumberProp,
  TForgotPasswordUserToDb,
  void,
  void
> = {
  fromDb: () => {
    /* do nothing */
  },
  toDb: data => ({
    username: `+${data.phoneNumber}`,
  }),
};
