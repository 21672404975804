import React, { FC } from 'react';
import { RadioGroup } from '@landlord-tech/ui-kit-opp/dist/components/Radio';
import { TRadioProps } from './Radio.types';
import SRadio from './Radio.styles';

const Radio: FC<TRadioProps> = props => {
  return <SRadio {...props} />;
};

export { RadioGroup, Radio };
