import { TMenuActionItem } from 'components/Base/Layout/Header/Header.props';
import { SMenuItemWrapper } from '../MobileHeader/CollapsedMenu/CollapsedMenu.styles';

const MenuActionItem = ({ menuItem, onClick }: TMenuActionItem) => {
  const { actionType, title } = menuItem;

  return (
    <SMenuItemWrapper onClick={() => onClick(actionType)}>
      {title}
    </SMenuItemWrapper>
  );
};

export default MenuActionItem;
