import { rest } from 'msw';
import { EResidencyUrls } from 'data/urls/residency.url';
import { TChangeResidencyFormDataDB } from 'data/types/residency.types';

const baseUrl = `${EResidencyUrls.RESIDENCY}`;

export const residencyHandlers = [
  rest.get(
    `*/${baseUrl}/${EResidencyUrls.APPLICATION}/:id/${EResidencyUrls.UNIT}/${EResidencyUrls.VERIFY}`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    },
  ),
  rest.put(`*/${baseUrl}/${EResidencyUrls.APPLICATION}`, (req, res, ctx) => {
    if ((req.body as TChangeResidencyFormDataDB).unitNumber === 'fail') {
      return res(ctx.status(400), ctx.json({ message: 'Bad request!' }));
    }
    return res(ctx.status(200), ctx.json({}));
  }),
];
