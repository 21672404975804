export enum EVisitationUrls {
  VISITATIONS = 'visitations',
  PROFILE_PICTURE = 'profile-picture',
  NAME = 'name',
  SPECIES = 'species',
  BREED = 'breed',
  PRIMARY_COLOR = 'primary-color',
  SECONDARY_COLOR_1 = 'secondary-color-1',
  SECONDARY_COLOR_2 = 'secondary-color-2',
  EXPLANATION = 'explanation',
  PRECAUTIONS = 'precautions',
  TRAINED_FOR = 'trained-for',
  WILL_BE_LEFT = 'will-be-left-without-supervision',
  REQUIRED = 'required-because-of-disability',
  DATE_RANGE = 'date-range',
}
