import { Row } from 'components/Base/Grid';
import { Icon, TIconNames } from 'components/Base/Icon';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from 'components/Base/Layout/Header/components/ProfilePicture';
import { useSignOutAmplifyMutation } from 'redux/apiSlice/authAmplify.slice';
import {
  clearAllCachedData,
  useAppDispatch,
  useAppSelector,
} from 'redux/store';
import { RoutesPaths } from 'routes/Routes.types';
import userSlice from 'redux/slices/user.slice';
import { TDropdownItemActions } from './ProfileMenu.types';
import ProfileDropdownMenu from './ProfileDropdownMenu';

const ProfileMenu = () => {
  const navigate = useNavigate();
  const [reFetch, { isSuccess }] = useSignOutAmplifyMutation();
  const dispatch = useAppDispatch();
  const impersonateByTenant = useAppSelector(
    state => state.userSlice.impersonateByTenant,
  );
  const logout = async () => {
    if (impersonateByTenant) {
      dispatch(userSlice.actions.removeImpersonateByTenant());
    } else {
      await reFetch().unwrap();
    }
    clearAllCachedData();
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    navigate(`/${RoutesPaths.LOGIN}`);
  }, [isSuccess]);

  const handleItemClick = (actionType: string) => {
    const DropdownItemActions: TDropdownItemActions = {
      logout,
    };

    DropdownItemActions[actionType as keyof TDropdownItemActions]();
  };
  return (
    <ProfileDropdownMenu onClick={handleItemClick}>
      <Row className="pointer">
        <ProfilePicture />
        <Icon icon={TIconNames.ARROW_DROPDOWN} size={12} />
      </Row>
    </ProfileDropdownMenu>
  );
};

export default ProfileMenu;
