import { TFetchConverter } from 'data/types/convertor.types';
import { TPageableData, TPageableDataUi } from 'data/types/pagableData.type';

export const pageableDataConverter: TFetchConverter<
  TPageableDataUi,
  TPageableData
> = {
  fromDb: data => {
    return {
      number: data.number,
      size: data.size,
      totalElements: data.totalElements,
      totalPages: data.totalPages,
    };
  },
};
