import {
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import authSlice, { TAuthSliceState } from './slices/auth.slice';
import appSlice, { TAppSliceState } from './slices/app.slice';
import { tenantApi } from './apiSlice/tenant.slices';
import { animalApi } from './apiSlice/animal.slice';
import { paymentApi } from './apiSlice/payment.slice';
import { docPacketApi } from './apiSlice/docPacket.slice';
import { animalTrainingApi } from './apiSlice/animalTraining.slice';
import { leasePacketApi } from './apiSlice/leasePacket.slice';
import { residencyApi } from './apiSlice/residency.slice';
import { policyApi } from './apiSlice/policy.slice';
import { storageApi } from './apiSlice/storage.slice';
import { usersApi } from './apiSlice/users.slice';
import { fileApi } from './apiSlice/file.slice';
import { curriculumApi } from './apiSlice/curriculum.slice';
import { visitationApi } from './apiSlice/visitation.slice';
import { AuthAmplify } from './apiSlice/authAmplify.slice';
import { AuthAmplifyWithPhoneNumber } from './apiSlice/authAmplifyWithPhoneNumber.slice';
import { AuthAmplifyWithEmail } from './apiSlice/authAmplifyWithEmail.slice';
import userSlice from './slices/user.slice';
import { applicationsApi } from './apiSlice/application.slice';
import { complaintApi } from './apiSlice/complaint.slice';
import { noticeApi } from './apiSlice/notice.slice';
import { TUserSliceState } from './store.types';
import { bugsnagClient } from '../ErrorBoundary';
import { onboardingApi } from './apiSlice/onboarding.slice';
// template-flag-0

type TState =
  | CombinedState<{
      [x: string]: unknown;
      userSlice: TUserSliceState;
      authSlice: TAuthSliceState;
      appSlice: TAppSliceState;
    }>
  | undefined;

const appReducer = combineReducers({
  [tenantApi.reducerPath]: tenantApi.reducer,
  [animalApi.reducerPath]: animalApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [docPacketApi.reducerPath]: docPacketApi.reducer,
  [animalTrainingApi.reducerPath]: animalTrainingApi.reducer,
  [leasePacketApi.reducerPath]: leasePacketApi.reducer,
  [residencyApi.reducerPath]: residencyApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [curriculumApi.reducerPath]: curriculumApi.reducer,
  [storageApi.reducerPath]: storageApi.reducer,
  [applicationsApi.reducerPath]: applicationsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [visitationApi.reducerPath]: visitationApi.reducer,
  [AuthAmplify.reducerPath]: AuthAmplify.reducer,
  [AuthAmplifyWithPhoneNumber.reducerPath]: AuthAmplifyWithPhoneNumber.reducer,
  [AuthAmplifyWithEmail.reducerPath]: AuthAmplifyWithEmail.reducer,
  [complaintApi.reducerPath]: complaintApi.reducer,
  [noticeApi.reducerPath]: noticeApi.reducer,
  [onboardingApi.reducerPath]: onboardingApi.reducer, // template-flag-1
  userSlice: userSlice.reducer,
  authSlice: authSlice.reducer,
  appSlice: appSlice.reducer,
});

const rootReducer = (state: TState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  // @ts-ignore
  return appReducer(state, action); // here type CombinedState<{ [x: string]: never which does not  working correct
};

const unhandledErrorMessages = (status: number) => {
  const messages: { [key: number]: string } = {
    500: 'Unhandled backend error',
    504: 'Backend deployment error',
  };
  return messages[status];
};
export const rtkQueryErrorLogger: Middleware = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if ([500, 504].includes(action.payload.status)) {
      bugsnagClient?.notify(
        new Error(
          `${action.payload.status}: ${unhandledErrorMessages(
            action.payload.status,
          )}`,
        ),
        e => {
          e.addMetadata('metadata', {
            url: action.payload?.data?.details,
            status: action.payload.status,
            message: unhandledErrorMessages(action.payload.status),
            type: action.type,
            endpoint: action.meta.arg.endpointName,
          });
        },
      );
    }
  }
  return next(action);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setupStore = (initialState: Record<string, any> = {}) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        tenantApi.middleware,
        animalApi.middleware,
        paymentApi.middleware,
        docPacketApi.middleware,
        animalTrainingApi.middleware,
        leasePacketApi.middleware,
        residencyApi.middleware,
        policyApi.middleware,
        storageApi.middleware,
        applicationsApi.middleware,
        fileApi.middleware,
        usersApi.middleware,
        curriculumApi.middleware,
        visitationApi.middleware,
        AuthAmplify.middleware,
        AuthAmplifyWithPhoneNumber.middleware,
        AuthAmplifyWithEmail.middleware,
        complaintApi.middleware,
        noticeApi.middleware,
        onboardingApi.middleware,
        rtkQueryErrorLogger,
        // template-flag-2
      ),
    preloadedState: initialState,
  });
export const store = setupStore();
export const clearAllCachedData = () => {
  store.dispatch(tenantApi.util.resetApiState());
  store.dispatch(animalApi.util.resetApiState());
  store.dispatch(paymentApi.util.resetApiState());
  store.dispatch(storageApi.util.resetApiState());
  store.dispatch(docPacketApi.util.resetApiState());
  store.dispatch(animalTrainingApi.util.resetApiState());
  store.dispatch(leasePacketApi.util.resetApiState());
  store.dispatch(policyApi.util.resetApiState());
  store.dispatch(residencyApi.util.resetApiState());
  store.dispatch(fileApi.util.resetApiState());
  store.dispatch(applicationsApi.util.resetApiState());
  store.dispatch(usersApi.util.resetApiState());
  store.dispatch(curriculumApi.util.resetApiState());
  store.dispatch(visitationApi.util.resetApiState());
  store.dispatch(AuthAmplify.util.resetApiState());
  store.dispatch(AuthAmplifyWithPhoneNumber.util.resetApiState());
  store.dispatch(AuthAmplifyWithEmail.util.resetApiState());
  store.dispatch(complaintApi.util.resetApiState());
  store.dispatch(noticeApi.util.resetApiState());
  store.dispatch(onboardingApi.util.resetApiState());
  // template-flag-3
};

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = cb =>
  useSelector(cb, shallowEqual);

setupListeners(store.dispatch);
