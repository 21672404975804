import cloneDeep from 'lodash/cloneDeep';
import { TAuthConverter } from '../types/convertor.types';
import {
  TSignInAmplifyDataToDb,
  IAmplifySignUpUserData,
  TForgotPasswordAmplifyWithEmailProp,
  TForgotPasswordUserToDb,
  TSignInWithEmailAmplifyDataUI,
  TAmplifyWithEmailUser,
  TAmplifySignInWithEmailUserData,
  TSignUpWithEmailAmplifyDataUI,
  TSignUpWithEmailAmplifyDataToDb,
  TSignUpWithEmailAmplifyDataUI2,
} from '../types/amplify.types';

export const signUpWithEmailAmplifyConvertor: TAuthConverter<
  TSignUpWithEmailAmplifyDataUI,
  TSignUpWithEmailAmplifyDataToDb,
  TSignUpWithEmailAmplifyDataUI2,
  IAmplifySignUpUserData
> = {
  toDb: data => ({ email: data.email.toLowerCase(), password: data.password }),
  fromDb: data => ({
    username: data?.user.username,
    password: data.password,
    userConfirmed: !!data?.userConfirmed,
    codeSend: true,
  }),
};

export const signInWithEmailAmplifyConvertor: TAuthConverter<
  TSignInWithEmailAmplifyDataUI,
  TSignInAmplifyDataToDb,
  TAmplifyWithEmailUser,
  TAmplifySignInWithEmailUserData
> = {
  fromDb: data => {
    const cognitoUser = cloneDeep(data);
    cognitoUser.username = data.username;
    cognitoUser.name = data.attributes?.name;
    cognitoUser.email = data.attributes?.email;
    cognitoUser.emailVerified = data.attributes?.email_verified;

    return cognitoUser;
  },
  toDb: data => ({
    username: data.email,
    password: data.password,
  }),
};

export const forgotPasswordWithEmailAmplifyConvertor: TAuthConverter<
  TForgotPasswordAmplifyWithEmailProp,
  TForgotPasswordUserToDb,
  void,
  void
> = {
  fromDb: () => {
    /* do nothing */
  },
  toDb: data => ({
    username: data.email,
  }),
};
