import React, { FC, Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { HubPayload } from '@aws-amplify/core';
import { useAppDispatch, useAppSelector } from 'redux/store';
import toast from 'components/Base/Notification';
import authSlice from 'redux/slices/auth.slice';
import { EVia } from 'data/types/amplify.types';
import { currentAuthUserConvertor } from 'data/convertors/amplify.convertors';
import { publicRoutes } from './RoutesData';
import { RoutesPaths } from './Routes.types';

export const PublicRoutes: FC<{
  isLoggedIn: boolean;
  isOnboarding: boolean;
}> = ({ isLoggedIn, isOnboarding }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const signInUser = useAppSelector(state => state.authSlice.signInUser);
  const dispatch = useAppDispatch();

  const authListener = ({ payload }: { payload: HubPayload }) => {
    const { event } = payload;
    const autoSignIn = localStorage.getItem('amplify-auto-sign-in');
    if (event === 'autoSignIn' && autoSignIn) {
      const amplifyUserData = currentAuthUserConvertor.fromDb(payload.data);
      dispatch(authSlice.actions.setAmplifyUser(amplifyUserData));
      navigate(
        { pathname: `/${RoutesPaths.ONBOARDING}`, search: location.search },
        {
          state: { viaEmail: signInUser?.via === EVia.EMAIL },
        },
      );
      dispatch(authSlice.actions.removeSignInUser());
    } else if (
      // This condition will work when redirected from email confirmation button
      // or confirmation without autoSignIn - true
      event === 'autoSignIn_failure' ||
      (event === 'confirmSignUp' && !autoSignIn)
    ) {
      if (signInUser?.username) {
        searchParams.set('username', signInUser.username);
      }
      toast({
        type: 'success',
        description:
          'You have successfully confirmed your account. Please provide your credentials to continue.',
      });
      dispatch(authSlice.actions.removeSignInUser());
      searchParams.delete('verificationCode');
      navigate({
        pathname: `/${RoutesPaths.LOGIN}`,
        search: searchParams.toString(),
      });
    }
  };

  useEffect(() => {
    Hub.listen('auth', authListener);

    return () => {
      Hub.remove('auth', authListener);
    };
  }, [signInUser]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/app/${RoutesPaths.ANIMAL}`);
    }
    if (isOnboarding) {
      navigate(`/${RoutesPaths.ONBOARDING}`);
    }
  }, [isLoggedIn, isOnboarding]);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={publicRoutes[0].path} />}
        />
        {publicRoutes
          .filter(item => import.meta.env.DEV || !item.onlyDev)
          .map(({ path, component }) => {
            const Component = component;
            return (
              <Route key={path} path={`${path}`} element={<Component />} />
            );
          })}
      </Routes>
    </Suspense>
  );
};
