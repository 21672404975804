import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextIcon from 'components/Base/TextIcon';
import { Icon, TIconNames } from 'components/Base/Icon';
import { FontWeights } from 'core/CssVariables';
import { getRoutePath } from 'helpers/utils';
import {
  EGoBackKeys,
  goBackOptions,
} from 'components/Base/Layout/Header/Header.data';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);

  const handleBackClick = () => {
    const backToPath = goBackOptions[path as EGoBackKeys].backTo;

    if (backToPath) {
      navigate(backToPath);
      return;
    }
    navigate(-1);
  };

  if (!Object.keys(goBackOptions).includes(path)) {
    return null;
  }

  return (
    <TextIcon
      onClick={handleBackClick}
      fontLevel={5}
      fontWeight={FontWeights.SemiBold}
      label={goBackOptions[path as EGoBackKeys].label}
      icon={<Icon icon={TIconNames.LEFT_ARROW} className="mr-2" />}
      className="pointer"
    />
  );
};

export default BackButton;
