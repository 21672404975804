import Button from 'components/Base/Button';
import { TButtonProps } from 'components/Base/Button/type';
import { Icon } from 'components/Base/Icon';
import Popover from 'components/Base/Popover';
import { Colors } from 'core/CssVariables';
import styled from 'styled-components';

export const SPopover = styled(Popover)``;

export const SIcon = styled(Icon)`
  cursor: pointer;
`;

export const SButton = styled(Button)<TButtonProps & { isMobile: boolean }>`
  && {
    background-color: ${Colors.PrimaryColor};
    border: none;
    position: fixed;
    bottom: 96px;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    box-shadow: 0 0 0 13px black;
    color: black;
    font-size: 33px;
    z-index: 10;
    padding: 0;
    border-radius: 50%;

    ${props =>
      props.isMobile &&
      `
      bottom: 26px;
      right: 26px;
      width: 36px;
      height: 36px;
      font-size: 24px;
    `}
  }
`;
