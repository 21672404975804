import Divider from 'components/Base/Divider';
import { TCollapsedMenuProps } from 'components/Base/Layout/Header/Header.props';
import { SCollapsedMenuWrapper } from './CollapsedMenu.styles';
import CollapsedMenuHeader from './components/CollapsedMenuHeader';
import CollapsedMenuPrimary from './components/CollapsedMenuPrimary';
import CollapsedMenuSecondary from './components/CollapsedMenuSecondary';

const CollapsedMenu = ({
  handleToggle = () => {
    /* do nothing */
  },
  onClick = () => {
    /* do nothing */
  },
  open,
}: TCollapsedMenuProps) => {
  return (
    <SCollapsedMenuWrapper open={open}>
      <CollapsedMenuHeader handleToggle={handleToggle} />
      <CollapsedMenuPrimary handleToggle={handleToggle} />
      <Divider />
      <CollapsedMenuSecondary onClick={onClick} handleToggle={handleToggle} />
    </SCollapsedMenuWrapper>
  );
};

export default CollapsedMenu;
