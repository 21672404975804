import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ENotificationDB } from 'data/types/notifications.types';
import { notificationsMapper } from 'data/datas/notificationsData';
import { notificationConverter } from 'data/convertors/notifications.convertors';
import { useAppDispatch } from 'redux/store';
import appSlice from 'redux/slices/app.slice';

// Todo: add user profile types and converters after change their apis
export const NotificationsRedirectLayer: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const actionType = notificationConverter.fromDb(
      searchParams.get('actionType') as ENotificationDB,
    );

    if (searchParams.get('actionType')) {
      const notificationObj = notificationsMapper[actionType];
      const resourceId = searchParams.get('resourceId');

      let { url } = notificationObj;
      if (resourceId) {
        url += `/${resourceId}`;
      }
      if (!notificationObj.private) {
        navigate(url);
        return;
      }
      // this link will be set in private routes
      dispatch(appSlice.actions.setRedirectLink(`/app${url}`));
    }
  }, []);

  return <>{children}</>;
};
