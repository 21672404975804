import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignOutAmplifyMutation } from 'redux/apiSlice/authAmplify.slice';
import { Col, Row } from 'components/Base/Grid';
import { Icon, TIconNames } from 'components/Base/Icon';
import ProfilePicture from 'components/Base/Layout/Header/components/ProfilePicture';
import { RoutesPaths } from 'routes/Routes.types';
import {
  clearAllCachedData,
  useAppDispatch,
  useAppSelector,
} from 'redux/store';
import userSlice from 'redux/slices/user.slice';
import CollapsedMenu from './CollapsedMenu';
// import Notifications from '../Notifications';
import BackButton from '../BackButton';
import { SHeaderWrapper } from './MobileHeader.styles';
import { TDropdownItemActions } from '../DesktopHeader/ProfileMenu/ProfileMenu.types';

const showMenuPaths = [
  `/app/${RoutesPaths.ANIMAL}`,
  `/app/${RoutesPaths.CURRICULUM}`,
  `/app/${RoutesPaths.VISITATION}`,
  `/app/${RoutesPaths.COMPLAINT}`,
  `/app/${RoutesPaths.KNOWLEDGE_BASE}`,
];

const MobileHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [reFetch, { isSuccess }] = useSignOutAmplifyMutation();
  const dispatch = useAppDispatch();
  const impersonateByTenant = useAppSelector(
    state => state.userSlice.impersonateByTenant,
  );
  const logout = async () => {
    if (impersonateByTenant) {
      dispatch(userSlice.actions.removeImpersonateByTenant());
    } else {
      try {
        await reFetch().unwrap();
        clearAllCachedData();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    navigate(`/${RoutesPaths.LOGIN}`);
  }, [isSuccess]);

  const handleItemClick = (actionType: string) => {
    const DropdownItemActions: TDropdownItemActions = {
      logout,
    };
    setToggle(false);
    DropdownItemActions[actionType as keyof TDropdownItemActions]();
  };

  const onProfilePictureClick = () => {
    navigate(`/app/${RoutesPaths.USER_PROFILE}`);
  };

  return (
    <>
      <SHeaderWrapper>
        <Row justify="space-between" align="middle">
          <Col>
            <Row align="middle" gutter={[24, 0]}>
              {showMenuPaths.includes(location.pathname) && (
                <Col>
                  <Icon
                    icon={TIconNames.MENU}
                    size={24}
                    onClick={() => setToggle(true)}
                  />
                </Col>
              )}
              <Col>
                <BackButton />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row justify="space-between" align="middle">
              {/* <Col>
                <Notifications />
              </Col> */}
              <Col>
                <ProfilePicture onClick={onProfilePictureClick} />
              </Col>
            </Row>
          </Col>
        </Row>
      </SHeaderWrapper>
      <CollapsedMenu
        open={toggle}
        handleToggle={setToggle}
        onClick={handleItemClick}
      />
    </>
  );
};

export default MobileHeader;
