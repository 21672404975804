import { EValidateionStatus } from 'data/types/generalTypes';
import { EUsersUrls } from 'data/urls/users.url';
import { emptySplitApi } from 'redux/helpers/slice.helpers';

const baseUrl = `${EUsersUrls.USERS}`;

export const usersApi = emptySplitApi('users').injectEndpoints({
  endpoints: build => ({
    resendInvitation: build.mutation<void, { username: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EUsersUrls.RESEND_INVITATION}`,
          method: 'POST',
          body,
        };
      },
    }),
    validateUsername: build.query<
      { status: EValidateionStatus },
      { username: string }
    >({
      query({ username }) {
        return {
          url: `${baseUrl}/${EUsersUrls.VALIDATE}`,
          params: { username },
        };
      },
    }),
  }),
});

export const { useResendInvitationMutation, useLazyValidateUsernameQuery } =
  usersApi;
