import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  ENoticeTitleDB,
  ENoticeTitleUI,
  ENoticeTypeDB,
  ENoticeTypeUI,
  TNotice,
  TNoticeUI,
  TNoticeList,
  TNoticeListUI,
  ENoticeTagDB,
  ENoticeTagUI,
  TCreator,
  TViolator,
  TCommentData,
  TNoticeCreateUI,
  TNoticeCreate,
  TAddCommentUI,
  TAddComment,
  TCommentDataUI,
  TAddAttachmentUI,
  TAddAttachment,
} from 'data/types/notice.types';
import { formattedDate } from 'helpers/utils';
import { fileConverter, selectOptionConverter } from './general.converters';

export const noticeTitleConvertor: TFetchConverter<
  ENoticeTitleUI,
  ENoticeTitleDB
> = {
  fromDb: data => {
    return ENoticeTitleUI[ENoticeTitleDB[data] as keyof typeof ENoticeTitleUI];
  },
};

export const noticeToDBTitleConvertor: TCreateConverter<
  ENoticeTitleUI,
  ENoticeTitleDB
> = {
  toDb: data => {
    const dataMapper: {
      [key in ENoticeTitleUI]: ENoticeTitleDB;
    } = {
      [ENoticeTitleUI.UNAUTHORIZED_ANIMAL]: ENoticeTitleDB.UNAUTHORIZED_ANIMAL,
      [ENoticeTitleUI.DAMAGE]: ENoticeTitleDB.DAMAGE,
      [ENoticeTitleUI.NOISE_DISTURBANCE]: ENoticeTitleDB.NOISE_DISTURBANCE,
      [ENoticeTitleUI.WASTE_MANAGEMENT]: ENoticeTitleDB.WASTE_MANAGEMENT,
      [ENoticeTitleUI.PET_RULE_VIOLATION]: ENoticeTitleDB.PET_RULE_VIOLATION,
      [ENoticeTitleUI.AGGRESSIVE_BEHAVIOR]: ENoticeTitleDB.AGGRESSIVE_BEHAVIOR,
      [ENoticeTitleUI.OTHER]: ENoticeTitleDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const noticeTypeConvertor: TFetchConverter<
  ENoticeTypeUI,
  ENoticeTypeDB
> = {
  fromDb: data => {
    const dataMapper: { [key in ENoticeTypeDB]: ENoticeTypeUI } = {
      [ENoticeTypeDB.COMPLAINT]: ENoticeTypeUI.COMPLAINT,
      [ENoticeTypeDB.VIOLATION]: ENoticeTypeUI.VIOLATION,
    };
    return dataMapper[data];
  },
};

export const noticeTagConvertor: TFetchConverter<ENoticeTagUI, ENoticeTagDB> = {
  fromDb: data => {
    const dataMapper: { [key in ENoticeTagDB]: ENoticeTagUI } = {
      [ENoticeTagDB.COMPLAINT]: ENoticeTagUI.COMPLAINT,
      [ENoticeTagDB.NOTICE]: ENoticeTagUI.NOTICE,
    };
    return dataMapper[data];
  },
};

export const noticeCreatorConvertor: TFetchConverter<
  { creatorName: string; creatorPhoto: string },
  TCreator
> = {
  fromDb: data => {
    return {
      creatorName: data.creatorName,
      creatorPhoto: data.creatorPhoto,
    };
  },
};

export const noticeViolatorConvertor: TFetchConverter<
  {
    violatorName: string | null;
    violatorPropertyName: string;
    violatorUnitName: string | null;
  },
  TViolator
> = {
  fromDb: data => {
    return {
      violatorName: data.violatorName,
      violatorPropertyName: data.violatorPropertyName,
      violatorUnitName: data.violatorUnitName,
    };
  },
};

export const noticeCommentsConvertor: TFetchConverter<
  TCommentDataUI,
  TCommentData
> = {
  fromDb: data => {
    return {
      commentsCount: data.commentsCount,
      commentsList: data.commentsList.map(comment => ({
        creatorId: comment.creatorId,
        creatorName: comment.creatorName,
        createdAt: formattedDate(`${comment.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
        owenComment: comment.owenComment,
        message: comment.massage,
      })),
    };
  },
};

export const getNoticeListConvertor: TFetchConverter<
  TNoticeListUI[],
  TNoticeList[]
> = {
  fromDb: data =>
    data.map(notice => ({
      id: notice.id,
      creatorName: notice.reporterName,
      createdAt: formattedDate(`${notice.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
      type: noticeTypeConvertor.fromDb(notice.type),
      tag: noticeTagConvertor.fromDb(notice.tag),
      title: noticeTitleConvertor.fromDb(notice.title),
      description: notice.description,
      commentsCount: notice.commentsCount,
      hasUnreadComment: notice.hasUnreadComment,
      viewed: notice.viewed,
    })),
};

export const getNoticeConvertor: TFetchConverter<TNoticeUI, TNotice> = {
  fromDb: noticeDetail => ({
    id: noticeDetail.id,
    tag: noticeTagConvertor.fromDb(noticeDetail.tag),
    conversationWith: noticeDetail.conversationWith,
    creator: noticeCreatorConvertor.fromDb({
      creatorName: noticeDetail.reporterName,
      creatorPhoto: noticeDetail.reporterPhoto,
    }),
    violator: noticeViolatorConvertor.fromDb({
      violatorName: noticeDetail.violatorName,
      violatorPropertyName: noticeDetail.violatorPropertyName,
      violatorUnitName: noticeDetail.violatorUnitName,
    }),
    createdAt: formattedDate(
      `${noticeDetail.createdAt}Z`,
      'MM/DD/YYYY hh:mm A',
    ),
    type: noticeTypeConvertor.fromDb(noticeDetail.type),
    title: noticeTitleConvertor.fromDb(noticeDetail.title),
    description: noticeDetail.description,
    hasUnreadComment: noticeDetail.hasUnreadComment,
    attachments: (noticeDetail.attachments || []).map(attachment =>
      fileConverter.fromDb(attachment),
    ),
    comments: noticeCommentsConvertor.fromDb({
      commentsCount: noticeDetail.commentsCount,
      commentsList: noticeDetail.comments,
    }),
    viewed: noticeDetail.viewed,
  }),
};

export const createNoticeConvertor: TCreateConverter<
  TNoticeCreateUI,
  TNoticeCreate
> = {
  toDb: noticeData => ({
    description: noticeData.description,
    propertyId: selectOptionConverter.toDb(noticeData.property).id || '',
    unitName: noticeData.unitName,
    violatorName: noticeData.violatorName,
    attachments:
      (noticeData.attachments || []).map(item => fileConverter.toDb(item)) ||
      [],
    title: noticeToDBTitleConvertor.toDb(
      selectOptionConverter.toDb(noticeData.title).id as ENoticeTitleUI,
    ),
  }),
};

export const addCommentConvertor: TCreateConverter<TAddCommentUI, TAddComment> =
  {
    toDb: data => ({
      noticeId: data.noticeId,
      message: data.comment,
    }),
  };

export const addAttchmentConvertor: TCreateConverter<
  TAddAttachmentUI,
  TAddAttachment
> = {
  toDb: data => ({
    noticeId: data.noticeId,
    attachments: data.attachments.map(item => fileConverter.toDb(item)) || [],
  }),
};
