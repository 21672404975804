export enum EApplicationUrls {
  APPLICATION = 'application',
  STEP = 'step',
  VERIFY = 'verify',
  ANIMAL = 'animal',
  DETAILS = 'details',
  TENANT = 'tenant',
  PAYMENT = 'payment',
  LEASE_PACKET = 'lease-packet',
  PROPERTIES = 'properties',
  PROPERTY = 'property',
  UNITS = 'units',
  METRIC = 'metric',
  SIGN = 'sign',
  PROPERTY_MANAGEMENT = 'property-management',
  RETRACT = 'retract',
  CONFIRM_PRIVACY_POLICY = 'confirm-privacy-policy',
  INCOMPLETE = 'incomplete',
  MOVE_IN_DATE = 'move-in-date',
}
