import styled from 'styled-components';
import { Menu, MenuItem } from 'components/Base/Menu';
import { Colors, FontSizes, FontWeights } from 'core/CssVariables';

export const SCollapsedMenuWrapper = styled.div<{
  open: boolean | undefined;
}>`
  padding: 24px;
  position: fixed;
  top: 0;
  right: ${props => (props.open ? 0 : '100%')};
  transition: right 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: ${Colors.White};
  z-index: 9999;
`;

export const SMenuWrapper = styled(Menu)`
  &.ant-menu {
    border: none;

    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: ${Colors.White};
        a {
          color: ${Colors.Black};
        }
      }
    }
  }
`;

export const SMenuItemWrapper = styled(MenuItem)`
  &.ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &.ant-menu-item {
    font-weight: ${FontWeights.Medium};
    font-size: ${FontSizes.FontMD}px;
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      color: ${Colors.Black};
      a {
        &.active {
          color: ${Colors.PrimaryColor};
        }
      }
      svg {
        margin-bottom: 3px;
      }
    }
  }
`;
