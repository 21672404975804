import React, { FC } from 'react';
import { useUploadFileMutation } from 'redux/apiSlice/storage.slice';
import { SImageUploader } from './ImageUploader.styles';
import { TImageUploaderProps } from './ImageUploader.types';

const ImageUploader: FC<TImageUploaderProps> = props => {
  const [uploadFile, { data }] = useUploadFileMutation();

  return (
    <SImageUploader uploadFile={uploadFile} uploadedImage={data} {...props} />
  );
};

export default ImageUploader;
