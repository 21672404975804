/* eslint-disable camelcase */
import { CognitoUser } from '@aws-amplify/auth';
import { ChallengeName, ISignUpResult } from 'amazon-cognito-identity-js';

export enum EVia {
  EMAIL = 'email',
  PHONE = 'phone',
}

// SIGN_UP_WITH_EMAIL
export type TSignUpWithEmailAmplifyProp = { email: string; password: string };

export type TSignUpWithEmailAmplifyDataUI = { email: string; password: string };

export type TSignUpWithEmailAmplifyDataUI2 = {
  username: string;
  userConfirmed: boolean;
  codeSend: boolean;
};

export type TSignUpWithEmailAmplifyDataToDb = {
  email: string;
  password: string;
};

// SIGN_UP_WITH_PHONE_NUMBER
export type TSignUpWithPhoneNumberAmplifyProp = {
  phoneNumber: string;
  password: string;
};

export type TSignUpWithPhoneNumberAmplifyDataUI = {
  phoneNumber: string;
  password: string;
};

export type TSignUpWithPhoneNumberAmplifyDataUI2 = {
  username: string;
  userConfirmed: boolean;
  codeSend: boolean;
};

export type TSignUpWithPhoneNumberAmplifyDataToDb = {
  phoneNumber: string;
  password: string;
};

// SIGN_UP
export type TSignUpAmplifyDataUI2 = {
  username: string;
  password: string;
  userConfirmed: boolean;
  codeSend: boolean;
  via: EVia;
};

export interface IAmplifySignUpUserData extends ISignUpResult {
  user: IUserProps & CognitoUser;
  password: string;
}

export type TAmplifySignUpUserData = ISignUpResult | null;

// RESEND
export type TResendSignUpAmplifyProps = { username: string };

// CONFIRMATION
export type TConfirmSignUpAmplifyProp = {
  username: string;
  verificationCode: string;
};

export type TConfirmSignUpAmplifyDataUi = {
  username: string;
  verificationCode: string;
};

export type TConfirmSignUpAmplifyDataToDb = {
  username: string;
  verificationCode: string;
};

// FORGOT_PASSWORD
export type TForgotPasswordUser = {
  username: string;
  verificationCode?: string;
};

export type TForgotPasswordUserToDb = { username: string };

export type TForgotPasswordSubmitAmplifyDataUI = {
  username: string;
  verificationCode: string;
  newPassword: string;
};

export type TForgotPasswordSubmitAmplifyToDb = {
  username: string;
  verificationCode: string;
  newPassword: string;
};

export type TForgotPasswordAmplifyWithUsernameProp = { username: string };

export type TForgotPasswordAmplifyWithEmailProp = { email: string };

export type TForgotPasswordAmplifyWithPhoneNumberProp = { phoneNumber: string };

// SIGN_IN_WITH_EMAIL
export type TSignInWithEmailAmplifyProp = { email: string; password: string };

export type TSignInWithEmailAmplifyDataUI = { email: string; password: string };

export interface TAmplifySignInWithEmailUserData extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  attributes?: UserEmailAttributes;
  challengeName?: ChallengeName;
}

// SIGN_IN_WITH_PHONE_NUMBER
export type TSignInWithPhoneNumberAmplifyProp = {
  phoneNumber: string;
  password: string;
};

export type TSignInWithPhoneNumberAmplifyDataUI = {
  phoneNumber: string;
  password: string;
};

export interface TAmplifySignInWithPhoneNumberUserData extends CognitoUser {
  username: string;
  name?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  attributes?: UserPhoneNumberAttributes;
  challengeName?: ChallengeName;
}

// SIGN_IN
export type TSignInAmplifyDataToDb = { username: string; password: string };

export interface IUserProps {
  username: string;
}

export interface IAmplifyUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  challengeName?: ChallengeName;
}

export interface TAmplifyWithPhoneNumberUser extends CognitoUser {
  username: string;
  name?: string;
  phoneNumber?: string;
  phoneNumberVerified?: boolean;
  challengeName?: ChallengeName;
}

export interface TAmplifyWithEmailUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  emailVerified?: boolean;
  challengeName?: ChallengeName;
}

export interface UserEmailAttributes {
  sub: string;
  email: string;
  email_verified: boolean;
  name: string;
  updated_at: string;
}

export interface UserPhoneNumberAttributes {
  sub: string;
  phone_number: string;
  phone_number_verified: boolean;
  name: string;
  updated_at: string;
}

export interface ICurrentAuthUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  attributes: UserEmailAttributes | UserPhoneNumberAttributes;
  challengeName?: ChallengeName;
}
export type TCurrentAuthUserUI = ICurrentAuthUser;

export type TCompleteNewPasswordUI = {
  user: CognitoUser;
  newPassword: string;
};
export type TCompleteNewPassword = ICurrentAuthUser;

export type TAmplifyError = {
  code: string;
  name: string;
  message: string;
  username: string;
  password: string;
};

export type TAmplifyErrorPayload = { data: TAmplifyError };

export enum EErrorCode {
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
}

export const LOGIN_FAILURE_MESSAGE = {
  'UserMigration failed with error Bad credentials.':
    'Incorrect username or password.',
};

export const RESET_PASSWORD_FAILURE_MESSAGE = {
  'UserMigration failed with error Bad credentials.': 'User does not exist.',
};

export enum ESignUpErrors {
  'PreSignUp failed with error User already exists.' = 'User already exists.',
  'PreSignUp failed with error Your country is not supported.' = 'Your country is not supported.',
}
