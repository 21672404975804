import { NavLink } from 'react-router-dom';
import { Row, Col } from 'components/Base/Grid';
import { MenuItem } from 'components/Base/Menu';
import { Icon, TIconNames } from 'components/Base/Icon';
import useFilterRoutes from 'hooks/useFilterRoutes';
import { SFooterWrapper, SMenu } from './Footer.styles';

const Footer = () => {
  const routes = useFilterRoutes();

  return (
    <SFooterWrapper>
      <Row justify="space-between" align="middle" className="container-lg">
        <Col sm={12} lg={4}>
          <Icon icon={TIconNames.LOGO_WITH_TEXT} size={170} />
        </Col>
        <Col sm={12} lg={8}>
          <SMenu mode="horizontal">
            {routes
              .filter(item => item.show)
              .map(route => {
                return (
                  <MenuItem key={route.path}>
                    <NavLink to={`${route.path}`}>{route.title}</NavLink>
                  </MenuItem>
                );
              })}
          </SMenu>
        </Col>
      </Row>
    </SFooterWrapper>
  );
};

export default Footer;
