import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  EAuthorizationStatus,
  EAuthorizationStatusUI,
  EVisitationStatus,
  EVisitationStatusUI,
  TAnimalNameUpdate,
  TAnimalNameUpdateUI,
  TAnimalRequiredUpdate,
  TAnimalRequiredUpdateUI,
  TBreedUpdate,
  TBreedUpdateUI,
  TPrimaryColorUpdate,
  TPrimaryColorUpdateUI,
  TPropertyManagement,
  TPropertyManagementUI,
  TSpeciesUpdate,
  TSpeciesUpdateUI,
  TVisitation,
  TStartEndDateUpdate,
  TStartEndDateUpdateUI,
  TVisitationFormData,
  TVisitationFormDataUI,
  TVisitationUI,
  TAnimalPictureUpdateUI,
  TAnimalPictureUpdate,
  TExplanationUpdateUI,
  TExplanationUpdate,
  TSecondaryColor1UpdateUI,
  TSecondaryColor2UpdateUI,
  TSecondaryColor2Update,
  TSecondaryColor1Update,
  TTrainedForUpdateUI,
  TTrainedForUpdate,
  TWillBeLeftUpdateUI,
  TWillBeLeftUpdate,
  TPrecautionsUpdateUI,
  TPrecautionsUpdate,
} from 'data/types/visitation.types';
import {
  profilePictureConvertor,
  selectOptionConverter,
  selectValueConvertor,
  yesNoOptionConverter,
} from 'data/convertors/general.converters';

export const visitationStatusConvertor: TFetchConverter<
  EVisitationStatusUI,
  EVisitationStatus
> = {
  fromDb: data => {
    const status: { [key in EVisitationStatus]: EVisitationStatusUI } = {
      [EVisitationStatus.APPROVED]: EVisitationStatusUI.APPROVED,
      [EVisitationStatus.PENDING]: EVisitationStatusUI.PENDING,
      [EVisitationStatus.REJECTED]: EVisitationStatusUI.REJECTED,
      [EVisitationStatus.ARCHIVED]: EVisitationStatusUI.ARCHIVED,
    };
    return status[data];
  },
};

export const authorizationStatusConvertor: TFetchConverter<
  EAuthorizationStatusUI,
  EAuthorizationStatus
> = {
  fromDb: data => {
    const status: { [key in EAuthorizationStatus]: EAuthorizationStatusUI } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUI.AUTHORIZED,
      [EAuthorizationStatus.UN_AUTHORIZED]:
        EAuthorizationStatusUI.UN_AUTHORIZED,
    };
    return status[data];
  },
};

export const propertyManagementConvertor: TFetchConverter<
  TPropertyManagementUI,
  TPropertyManagement
> = {
  fromDb: data => ({
    name: data.name,
    logo: data.logo,
    propertyName: data.propertyName,
    unitNumber: data.unitNumber,
  }),
};

export const createVisitationConvertor: TCreateConverter<
  TVisitationFormDataUI,
  TVisitationFormData
> = {
  toDb: data => ({
    residenceId: data.residenceId,
    name: data.name,
    profilePicture: data.profilePicture
      ? profilePictureConvertor.toDb(data.profilePicture)
      : null,
    species: selectOptionConverter.toDb(data.species),
    breed: data.breed ? selectOptionConverter.toDb(data.breed) : null,
    primaryColor: selectOptionConverter.toDb(data.primaryColor),
    secondaryColor1: data.secondaryColor1?.id || null,
    secondaryColor2: data.secondaryColor2?.id || null,
    startDate: data.startDateTime,
    endDate: data.endDateTime,
    explanation: data.explanation,
    willBeLeftWithoutSupervision: yesNoOptionConverter.toDb(
      data.willBeLeftWithoutSupervision,
    ),
    precautions: data.precautions,
    didUserConfirmLearningEfforts: data.didUserConfirmLearningEfforts || false,
    isServiceAnimal: yesNoOptionConverter.toDb(data.isServiceAnimal),
    trainedFor: data.trainedFor || null,
    requiredBecauseOfDisability: yesNoOptionConverter.toDb(
      data.requiredBecauseOfDisability,
    ),
  }),
};

export const visitationConvertor: TFetchConverter<TVisitationUI, TVisitation> =
  {
    fromDb: data => ({
      id: data.id,
      animalProfilePicture: data.animalProfilePicture,
      animalName: data.animalName,
      species: selectValueConvertor.fromDb(data.species),
      breed: data.breed && selectValueConvertor.fromDb(data.breed),
      primaryColor:
        data.primaryColor && selectValueConvertor.fromDb(data.primaryColor),
      secondaryColor1:
        data.secondaryColor1 &&
        selectValueConvertor.fromDb(data.secondaryColor1),
      secondaryColor2:
        data.secondaryColor2 &&
        selectValueConvertor.fromDb(data.secondaryColor2),
      startDate: `${data.startDate}Z`,
      endDate: `${data.endDate}Z`,
      explanation: data.explanation,
      willBeLeftWithoutSupervision: yesNoOptionConverter.fromDb(
        data.willBeLeftWithoutSupervision,
      ),
      precautions: data.precautions,
      didUserConfirmLearningEfforts: data.didUserConfirmLearningEfforts,
      status: visitationStatusConvertor.fromDb(data.status),
      isServiceAnimal: data.isServiceAnimal,
      trainedFor: data.trainedFor,
      requiredBecauseOfDisability: yesNoOptionConverter.fromDb(
        data.requiredBecauseOfDisability,
      ),
      authorization: authorizationStatusConvertor.fromDb(data.authorization),
      propertyManagement: propertyManagementConvertor.fromDb(
        data.propertyManagement,
      ),
      rejectReason: data.rejectReason,
      canEdit: data.modifiable,
    }),
  };

export const animalPictureUpdateConvertor: TCreateConverter<
  TAnimalPictureUpdateUI,
  TAnimalPictureUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    profilePicture: data.value,
  }),
};

export const animalNameUpdateConvertor: TCreateConverter<
  TAnimalNameUpdateUI,
  TAnimalNameUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    name: data.value,
  }),
};

export const speciesUpdateConvertor: TCreateConverter<
  TSpeciesUpdateUI,
  TSpeciesUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    specie: selectOptionConverter.toDb(data.value),
  }),
};

export const breedUpdateConvertor: TCreateConverter<
  TBreedUpdateUI,
  TBreedUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    breed: selectOptionConverter.toDb(data.value),
  }),
};

export const primaryColorUpdateConvertor: TCreateConverter<
  TPrimaryColorUpdateUI,
  TPrimaryColorUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    primaryColor: selectOptionConverter.toDb(data.value),
  }),
};

export const secondaryColor1UpdateConvertor: TCreateConverter<
  TSecondaryColor1UpdateUI,
  TSecondaryColor1Update
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    secondaryColor1: data.value,
  }),
};

export const secondaryColor2UpdateConvertor: TCreateConverter<
  TSecondaryColor2UpdateUI,
  TSecondaryColor2Update
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    secondaryColor2: data.value,
  }),
};

export const visitationStartEndDateUpdateConvertor: TCreateConverter<
  TStartEndDateUpdateUI,
  TStartEndDateUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    startDate: data.startDateTime,
    endDate: data.endDateTime,
  }),
};

export const animalRequiredUpdateConvertor: TCreateConverter<
  TAnimalRequiredUpdateUI,
  TAnimalRequiredUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    requiredBecauseOfDisability: yesNoOptionConverter.toDb(data.value),
  }),
};

export const explanationUpdateConvertor: TCreateConverter<
  TExplanationUpdateUI,
  TExplanationUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    explanation: data.value,
  }),
};

export const trainedForUpdateConvertor: TCreateConverter<
  TTrainedForUpdateUI,
  TTrainedForUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    trainedFor: data.value,
  }),
};

export const willBeLeftUpdateConvertor: TCreateConverter<
  TWillBeLeftUpdateUI,
  TWillBeLeftUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    willBeLeftWithoutSupervision: yesNoOptionConverter.toDb(data.willBeLeft),
    precautions: data.precautions,
  }),
};

export const precautionsUpdateConvertor: TCreateConverter<
  TPrecautionsUpdateUI,
  TPrecautionsUpdate
> = {
  toDb: data => ({
    animalVisitationId: data.visitationId,
    precautions: data.value,
  }),
};
