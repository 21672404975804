import React, { FC } from 'react';
import { TMobileDrawerProps } from '@landlord-tech/ui-kit-opp';
import { TModalProps, TModalDeleteProps, ModalWidthEnum } from './Modal.types';
import {
  SMobileDrawer,
  SModal,
  SModalDeleteConfirmation,
} from './Modal.styles';

const Modal: FC<Omit<TModalProps, 'width'>> = props => {
  return <SModal {...props} />;
};

const ModalDeleteConfirmation: FC<Omit<TModalDeleteProps, 'width'>> = props => {
  return <SModalDeleteConfirmation {...props} />;
};

const MobileDrawer: FC<TMobileDrawerProps> = props => {
  return <SMobileDrawer {...props} />;
};

export default Modal;
export { ModalDeleteConfirmation, MobileDrawer };
export { ModalWidthEnum };
