import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Text } from 'components/Base/Typography';
import { Colors, Screens } from 'core/CssVariables';
import { TOnChangeParam, TSelectProps, TValueType } from './Select.types';
import { SSelect } from './Select.styles';

const Select: FC<TSelectProps> = ({ label, className, ...props }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });

  return (
    <div className={className}>
      {label && (
        <Text fontLevel={6} color={Colors.LightGrey} className="mb-1">
          {label}
        </Text>
      )}
      <SSelect
        isMobile={isMobile}
        menuPosition="fixed"
        menuShouldBlockScroll
        {...props}
      />
    </div>
  );
};

export default Select;

export type { TValueType, TOnChangeParam };
