import { authSplitApi } from 'redux/helpers/slice.helpers';
import { ECurriculumUrls } from 'data/urls/curriculum.url';
import {
  TTraining,
  TTrainingAccess,
  TTrainingAccessUI,
  TTrainingDetails,
  TTrainingDetailsUI,
  TTrainingPassedUpdateUI,
  TTrainingUI,
} from 'data/types/curriculum.types';
import { trainingConvertor } from 'data/convertors/curriculum.convertors';

const baseUrl = `${ECurriculumUrls.CURRICULUM}`;

export const curriculumApi = authSplitApi('curriculum', [
  'trainings',
  'training',
]).injectEndpoints({
  endpoints: build => ({
    getTrainings: build.query<TTrainingUI[], void>({
      query() {
        return {
          url: `${baseUrl}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTraining[]) =>
        data.map(item => trainingConvertor.fromDb(item)),
      providesTags: ['trainings'],
    }),
    getTraining: build.query<TTrainingDetailsUI, { trainingId: string }>({
      query({ trainingId }) {
        return {
          url: `${baseUrl}/${trainingId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTrainingDetails) =>
        trainingConvertor.fromDb(data),
      providesTags: ['training'],
    }),
    getTrainingAccess: build.mutation<
      TTrainingAccessUI,
      { applicationId: string }
    >({
      query({ applicationId }) {
        return {
          url: `${baseUrl}/${ECurriculumUrls.APPLICATION}/${applicationId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TTrainingAccess) =>
        data ? trainingConvertor.fromDb(data) : null,
      invalidatesTags: ['training', 'trainings'],
    }),
    updateTrainingPassed: build.mutation<void, TTrainingPassedUpdateUI>({
      query({ courseId, checkpointKey }) {
        return {
          url: `${baseUrl}/${courseId}/${checkpointKey}`,
          method: 'PATCH',
        };
      },
    }),
  }),
});

export const {
  useGetTrainingsQuery,
  useGetTrainingQuery,
  useGetTrainingAccessMutation,
  useUpdateTrainingPassedMutation,
} = curriculumApi;
