import React, { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { Icon, TIconNames } from 'components/Base/Icon';
import { SImage } from './ProfilePicture.styles';
import { TProfilePictureProps } from './ProfilePicture.types';

const ProfilePicture: FC<TProfilePictureProps> = ({ onClick }) => {
  const user = useAppSelector(state => state.userSlice.user);
  if (!user?.profilePicture) {
    return (
      <Icon
        icon={TIconNames.PROFILE}
        size={32}
        className="mr-1"
        onClick={onClick}
      />
    );
  }

  return (
    <SImage
      src={user.profilePicture}
      alt="Profile picture"
      shape="circle"
      width={38}
      className="mr-1"
      onClick={onClick}
    />
  );
};

export default ProfilePicture;
