import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  TApplyLeasePacketCode,
  TApplyLeasePacketCodeUI,
  TGetLeasePacketAccess,
  TGetLeasePacketAccessUI,
  TLeasePacketDB,
  TLeasePacketUI,
  TValidateLeasePacketCode,
  TValidateLeasePacketCodeUI,
} from 'data/types/leasePacket.types';

export const leasePacketAccessConvertor: TCreateConverter<
  TGetLeasePacketAccess,
  TGetLeasePacketAccessUI
> = {
  toDb: data => ({
    code: data.code?.toUpperCase() || '',
  }),
};

export const validateLeasePacketCodeConvertor: TCreateConverter<
  TValidateLeasePacketCode,
  TValidateLeasePacketCodeUI
> = {
  toDb: data => ({
    code: data.code?.toUpperCase() || '',
  }),
};

export const applyLeasePacketCodeConvertor: TCreateConverter<
  TApplyLeasePacketCode,
  TApplyLeasePacketCodeUI
> = {
  toDb: data => ({
    code: data.code?.toUpperCase() || '',
    tenantId: data.tenantId,
  }),
};

export const leasePacketDataConverter: TFetchConverter<
  TLeasePacketUI,
  TLeasePacketDB
> = {
  fromDb: data => {
    return {
      generatedDocUrl: data.generatedDocUrl,
      leasePacketId: data.leasePacketId,
      propertyManagementName: data.propertyManagementName,
      propertyManagementLogo: data.propertyManagementLogo,
      propertyManagerId: data.propertyManagementId,
      propertyManagerName: data.propertyManagerName,
      propertyManagementId: data.propertyManagementId,
    };
  },
};
