import { TConvertor } from 'data/types/convertor.types';
import {
  ENotificationUI,
  ENotificationDB,
} from 'data/types/notifications.types';

export const notificationConverter: TConvertor<
  ENotificationUI,
  ENotificationDB
> = {
  fromDb: data => {
    const dataMapper: { [key in ENotificationDB]: ENotificationUI } = {
      [ENotificationDB.ANIMAL_APPLICATION_APPROVED]:
        ENotificationUI.ANIMAL_APPLICATION_APPROVED,
      [ENotificationDB.ANIMAL_APPLICAION_REJECTED]:
        ENotificationUI.ANIMAL_APPLICAION_REJECTED,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in ENotificationUI]: ENotificationDB } = {
      [ENotificationUI.ANIMAL_APPLICATION_APPROVED]:
        ENotificationDB.ANIMAL_APPLICATION_APPROVED,
      [ENotificationUI.ANIMAL_APPLICAION_REJECTED]:
        ENotificationDB.ANIMAL_APPLICAION_REJECTED,
    };
    return dataMapper[data];
  },
};
