import { TFetchConverter } from 'data/types/convertor.types';
import { TDocHtmlUI } from 'data/types/policy.types';

export const docHtmlConvertor: TFetchConverter<TDocHtmlUI, string> = {
  fromDb: data => {
    const content = {
      html: /<body.*?>([\s\S]*)<\/body>/.exec(data)?.[1] || '',
      css: /<style.*?>([\s\S]*)<\/style>/.exec(data)?.[1] || '',
    };

    // To convert wierd character to bullet points
    content.html = content.html.replaceAll('​&nbsp;', '&#9679;');
    return content;
  },
};
