import { rest } from 'msw';
import { EApplicationUrls } from 'data/urls/application.url';
import { TRetractApplicationUI } from 'data/types/application.types';

const baseUrl = `${EApplicationUrls.APPLICATION}`;
export const applicationHandlers = [
  rest.patch(`*/${baseUrl}/${EApplicationUrls.RETRACT}`, (req, res, ctx) => {
    if ((req.body as TRetractApplicationUI).other === 'fail') {
      return res(ctx.status(400), ctx.json({ message: 'Bad request!' }));
    }
    return res(ctx.status(200), ctx.json({} as TRetractApplicationUI));
  }),
  rest.get(
    `*/${EApplicationUrls.LEASE_PACKET}/${EApplicationUrls.PROPERTY}/${EApplicationUrls.UNITS}`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json([]));
    },
  ),
];
