import { useAppSelector } from 'redux/store';
import { PrivateRouteType } from 'routes/Routes.types';
import { privateRoutes } from 'routes/RoutesData';

const useFilterRoutes = () => {
  const user = useAppSelector(state => state.userSlice.user);
  const isBlocked = localStorage.getItem('isBlocked') === 'true';
  const isVisitationBlocked =
    localStorage.getItem('isVisitationBlocked') === 'true';

  let privateRoutesWitoutNotice = privateRoutes.filter(
    item => !['Animal complaint'].includes(item.title),
  );
  if (isVisitationBlocked) {
    privateRoutesWitoutNotice = privateRoutesWitoutNotice.filter(
      item => !['Visitation'].includes(item.title),
    );
  }
  if (user?.hasTraining) {
    return isBlocked ? privateRoutesWitoutNotice : privateRoutes;
  }
  const filteredPrivateRoutes: PrivateRouteType[] = (
    isBlocked ? privateRoutesWitoutNotice : privateRoutes
  ).filter(item => item.title !== 'Curriculum');

  return filteredPrivateRoutes;
};

export default useFilterRoutes;
