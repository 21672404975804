import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { worker } from './test/mocks/browser';

const prepare = () => {
  if (import.meta.env.MODE === 'development') {
    return worker.start();
  }
  return Promise.resolve();
};

prepare().then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
