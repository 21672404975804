import mammoth from 'mammoth';

const downloadFile = (file: Blob, fileName: string) => {
  const link = window.URL.createObjectURL(file);
  const anchorTag = document.createElement('a');
  anchorTag.setAttribute('href', link);
  let fn = `${fileName}`;
  const fileNameMap = fileName.split('.');

  if (
    file.type === 'application/octet-stream' &&
    !['csv'].includes(fileNameMap[fileNameMap.length - 1])
  ) {
    fn += '.doc';
  }
  anchorTag.setAttribute('download', fn);
  anchorTag.style.display = 'none';
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
};

export const downloadFileData = (url: string, name: string) =>
  fetch(url).then(response =>
    response.blob().then(blob => downloadFile(blob, name)),
  );

export const filePreviewData = (fileUrl: string) =>
  fetch(fileUrl)
    .then(response => response.text())
    .then(text => text);

export const getArrayBufferFromUrl = async (url: string) => {
  const response = await fetch(url);
  return response.arrayBuffer();
};

export const convertDocToHtml = async (file: ArrayBuffer) => {
  const result = await mammoth.convertToHtml({ arrayBuffer: file });
  return result.value;
};
