import { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import CreateAnimalForm from 'pages/private/Animal/components/CreateAnimal/CreateAnimalModal/CreateAnimalForm';
import Modal, { ModalWidthEnum } from 'components/Base/Modal';
import toast from 'components/Base/Notification';
import {
  EAnimalTypesUI,
  TAnimalFormDataUI,
  TCompleteAnimalFormDataUI,
} from 'data/types/animal.types';
import { TValidateFieldsError } from 'data/types/generalTypes';
import { EText } from 'data/datas/genericData';
import {
  animalApi,
  useCompleteAnimalMutation,
  useCreateAnimalMutation,
} from 'redux/apiSlice/animal.slice';
import useForm from 'hooks/useForm';
import { useDispatch } from 'react-redux';
import { tenantApi } from 'redux/apiSlice/tenant.slices';
import { applicationsApi } from 'redux/apiSlice/application.slice';
import { modalTitle } from './CreateAnmialModal.data';
import { TCreateAnimalModalProps } from './CreateAnimalModal.types';

const CreateAnimalModal = forwardRef(
  (
    {
      onCreateSuccess = () => {
        /* do nothing */
      },
    }: TCreateAnimalModalProps,
    ref,
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [animalType, setAnimalType] = useState(EAnimalTypesUI.PET);
    const [actionType, setActionType] = useState('');
    const [navigateUrl, setNavigateUrl] = useState('');
    const [animalId, setAnimalId] = useState('');
    const { form, setCustomFieldValue } = useForm<
      TAnimalFormDataUI | TCompleteAnimalFormDataUI
    >();
    const [createAnimal, { isLoading: createLoading }] =
      useCreateAnimalMutation();
    const [completeAnimal, { isLoading: completeLoading }] =
      useCompleteAnimalMutation();

    useImperativeHandle(ref, () => ({
      open: (animal: EAnimalTypesUI, type: string, url: string) => {
        setActionType(type);
        if (type === 'create') {
          setAnimalType(animal);
          setOpen(true);
          return;
        }
        setNavigateUrl(url);
        setAnimalId(animal);
        setOpen(true);
      },
    }));

    const handleSubmit = async () => {
      try {
        if (actionType === 'create') {
          const values = (await form.validateFields()) as TAnimalFormDataUI;
          const data = await createAnimal({
            ...values,
            animalType:
              EAnimalTypesUI[animalType as keyof typeof EAnimalTypesUI],
          }).unwrap();
          onCreateSuccess(data, animalType);
        } else {
          const values =
            (await form.validateFields()) as TCompleteAnimalFormDataUI;
          const isConvertedToPet = await completeAnimal({
            ...values,
            animalId,
          }).unwrap();
          onCreateSuccess({ id: animalId, isConvertedToPet }, animalType);
          dispatch(tenantApi.util.invalidateTags(['tenant']));
          dispatch(applicationsApi.util.invalidateTags(['steps']));
          if (navigateUrl) {
            navigate(navigateUrl, { state: { animalType } });
          }
        }
        setOpen(false);
        toast({
          description: `Animal has been successfully ${actionType}d`,
          type: 'success',
        });
      } catch (e) {
        if (!(e as TValidateFieldsError)?.errorFields) {
          const message = get(e, 'data.message', EText.DEFAULT_ERROR) as string;
          toast({
            description: message,
            type: 'error',
          });
        }
      }
    };

    return (
      <Modal
        title={
          actionType === 'create'
            ? `Create ${modalTitle[animalType]}`
            : `Complete animal `
        }
        size={ModalWidthEnum.Large}
        visible={open}
        okText={capitalize(actionType)}
        onOk={handleSubmit}
        afterClose={() => {
          form.resetFields();
          setAnimalId('');
          dispatch(animalApi.util.invalidateTags(['animalById']));
        }}
        onCancel={() => setOpen(false)}
        confirmLoading={createLoading || completeLoading}
        okButtonProps={{ form: 'create-animal', htmlType: 'submit' }}
        destroyOnClose
      >
        <CreateAnimalForm
          animalType={animalType}
          animalId={animalId}
          actionType={actionType}
          formObj={{ form, setCustomFieldValue }}
        />
      </Modal>
    );
  },
);

export default CreateAnimalModal;
