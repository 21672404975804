import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { TUserSliceState } from '../store.types';

const getBaseUrl = (forAdmin?: boolean) =>
  `${import.meta.env.REACT_APP_HOSTNAME}/${
    !forAdmin ? 'tenant' : 'admin'
  }/api/v2`;

export const authSplitApi = (
  reducerPath: string,
  tagTypes?: string[],
  forAdmin?: boolean,
) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: getBaseUrl(forAdmin),
      prepareHeaders: async (headers, { getState }) => {
        try {
          const session = await Auth.currentSession();
          const idTokenExpire = session.getIdToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            try {
              const currentAuthUser = await Auth.currentAuthenticatedUser();
              currentAuthUser.refreshSession(refreshToken, (err: string) => {
                if (err) {
                  localStorage.clear();
                  window.location.replace(`/login`);
                } else {
                  headers.set(
                    'authorization',
                    `Bearer ${session.getIdToken().getJwtToken()}`,
                  );
                }
              });
            } catch (err) {
              localStorage.clear();
              window.location.replace(`/login`);
            }
          } else {
            headers.set(
              'authorization',
              `Bearer ${session.getIdToken().getJwtToken()}`,
            );
          }
        } catch (err) {
          console.log(err, 'err');
          localStorage.clear();
          window.location.replace(`/login`);
        }
        const tenantId = (getState() as { userSlice: TUserSliceState })
          .userSlice.impersonateByTenant;
        if (tenantId) {
          headers.set('x-user-id', tenantId);
        } else {
          headers.delete('x-user-id');
        }
        return headers;
      },
    }),
    tagTypes: tagTypes || [],
    endpoints: () => ({}),
  });

export const emptySplitApi = (reducerPath: string) =>
  createApi({
    reducerPath,
    baseQuery: fetchBaseQuery({
      baseUrl: getBaseUrl(false),
    }),
    endpoints: () => ({}),
  });
