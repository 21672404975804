import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const AccessLeasePacket = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leasePacketCode = searchParams.get('leasePacketCode');

  useEffect(() => {
    if (leasePacketCode) {
      localStorage.setItem('leasePacketCode', leasePacketCode);
    }
    if (params.leasePacketCode) {
      localStorage.setItem('leasePacketCode', params.leasePacketCode);
    }
    navigate(`/signup`, { replace: true });
  }, []);
  return null;
};

export default AccessLeasePacket;
