import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import appSlice from 'redux/slices/app.slice';
import AccessLeasePacket from 'pages/public/AccessLeasePacket';
import { useGetResidencyDataQuery } from 'redux/apiSlice/residency.slice';
import {
  standartPlanProperties,
  standartPlanPropertyManagements,
} from 'data/datas/genericData';
import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { RoutesPaths } from './Routes.types';
import { useCurrentAuthUserAmplifyMutation } from '../redux/apiSlice/authAmplify.slice';
import { ETenantActionUI, TTenantDataUI } from '../data/types/tenant.types';
import { useGetTenantProfileQuery } from '../redux/apiSlice/tenant.slices';

const Impersonate = lazy(() => import('pages/public/Impersonation'));
const Certificate = lazy(() => import('pages/public/Certificate'));

const RoutesPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const amplifyUser = useAppSelector(state => state.authSlice.amplifyUser);
  useGetTenantProfileQuery(null, {
    skip:
      !amplifyUser || amplifyUser?.challengeName === 'NEW_PASSWORD_REQUIRED',
  });
  const { data } = useGetResidencyDataQuery(null, {
    skip:
      !amplifyUser || amplifyUser?.challengeName === 'NEW_PASSWORD_REQUIRED',
  });

  const isBlocked =
    data?.length === 1 &&
    data?.some(
      residency =>
        standartPlanPropertyManagements.includes(
          residency.propertyManagementName,
        ) || standartPlanProperties.includes(residency.propertyName),
    );

  const isVisitationBlocked =
    data?.length === 1 &&
    data?.some(
      residency => residency.propertyManagementName === 'Urban Land Interests',
    );

  localStorage.setItem('isBlocked', String(isBlocked));
  localStorage.setItem('isVisitationBlocked', String(isVisitationBlocked));

  const { user, isLoading } = useAppSelector(state => ({
    user: state.userSlice.user as TTenantDataUI | null,
    isLoading: state.userSlice.isLoading as boolean,
  }));

  const [reRetch] = useCurrentAuthUserAmplifyMutation();

  useEffect(() => {
    if (!user) {
      return;
    }
    reRetch(null);
  }, [user]);

  useEffect(() => {
    if (!user && location.pathname.search(/app/g) !== -1) {
      dispatch(appSlice.actions.setRedirectLink(location.pathname));
    }
  }, []);
  const isOnboarding = !(
    isLoading ||
    amplifyUser?.challengeName === 'NEW_PASSWORD_REQUIRED' ||
    user?.requiredAction === ETenantActionUI.NO_ACTION
  );

  return (
    <Suspense fallback={isLoading}>
      <Routes>
        <Route
          path="/*"
          element={
            <PublicRoutes
              isOnboarding={isOnboarding}
              isLoggedIn={user?.requiredAction === ETenantActionUI.NO_ACTION}
            />
          }
        />
        <Route path="app/*" element={<PrivateRoutes isLoggedIn />} />
        <Route path={RoutesPaths.IMPERSONATION} element={<Impersonate />} />
        <Route
          path={RoutesPaths.LEASE_PACKET}
          element={<AccessLeasePacket />}
        />
        <Route
          path={RoutesPaths.LEASE_PACKET_CODE}
          element={<AccessLeasePacket />}
        />
        <Route path={RoutesPaths.CERTIFICATE} element={<Certificate />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesPage;
