import { authSplitApi } from 'redux/helpers/slice.helpers';
import { ELeasePacketUrls } from 'data/urls/leasePacket.url';
import {
  TGetLeasePacketAccessUI,
  TLeasePacketDB,
  TLeasePacketUI,
  TValidateLeasePacketCodeUI,
} from 'data/types/leasePacket.types';
import {
  leasePacketAccessConvertor,
  leasePacketDataConverter,
  validateLeasePacketCodeConvertor,
} from 'data/convertors/leasePacket.convertors';

export const leasePacketApi = authSplitApi('leasePacket').injectEndpoints({
  endpoints: build => ({
    getLeasePacketAccess: build.mutation<string, TGetLeasePacketAccessUI>({
      query(body) {
        return {
          url: `${ELeasePacketUrls.LEASE_PACKET}`,
          method: 'POST',
          body: leasePacketAccessConvertor.toDb(body),
        };
      },
      invalidatesTags: [],
    }),
    validateLeasePacketCode: build.mutation<
      TLeasePacketUI,
      TValidateLeasePacketCodeUI
    >({
      query(body) {
        return {
          url: `${ELeasePacketUrls.LEASE_PACKET}/${ELeasePacketUrls.CODE}`,
          method: 'GET',
          params: validateLeasePacketCodeConvertor.toDb(body),
        };
      },
      invalidatesTags: [],
      transformResponse: (data: TLeasePacketDB) =>
        leasePacketDataConverter.fromDb(data),
    }),
  }),
});

export const {
  useGetLeasePacketAccessMutation,
  useValidateLeasePacketCodeMutation,
} = leasePacketApi;
