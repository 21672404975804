import { authSplitApi } from 'redux/helpers/slice.helpers';
import { ENoticeUrls } from 'data/urls/notice.url';
import {
  TNotice,
  TNoticeUI,
  TNoticeList,
  TNoticeListUI,
  TAddCommentUI,
  TNoticeCreateUI,
  TAddAttachmentUI,
} from 'data/types/notice.types';
import {
  getNoticeListConvertor,
  getNoticeConvertor,
  addCommentConvertor,
  createNoticeConvertor,
  addAttchmentConvertor,
} from 'data/convertors/notice.convertors';

export const noticeApi = authSplitApi('notice', [
  'noticeList',
  'notice',
]).injectEndpoints({
  endpoints: build => ({
    getNoticeList: build.query<TNoticeListUI[], void>({
      query() {
        return {
          url: `${ENoticeUrls.NOTICE}`,
          method: 'GET',
        };
      },
      providesTags: ['noticeList'],
      transformResponse: (data: TNoticeList[]) =>
        getNoticeListConvertor.fromDb(data),
    }),
    getNotice: build.query<TNoticeUI, string>({
      query(id) {
        return {
          url: `${ENoticeUrls.NOTICE}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['notice'],
      keepUnusedDataFor: 0.0001,
      transformResponse: (data: TNotice) => getNoticeConvertor.fromDb(data),
    }),
    createNotice: build.mutation<void, TNoticeCreateUI>({
      query(body) {
        return {
          url: `${ENoticeUrls.NOTICE}`,
          method: 'POST',
          body: createNoticeConvertor.toDb(body),
        };
      },
      invalidatesTags: ['noticeList', 'notice'],
    }),
    addComment: build.mutation<void, TAddCommentUI>({
      query(data) {
        return {
          url: `${ENoticeUrls.NOTICE}/${ENoticeUrls.COMMENT}`,
          method: 'PATCH',
          body: addCommentConvertor.toDb(data),
        };
      },
      invalidatesTags: ['notice'],
    }),
    addAttchment: build.mutation<void, TAddAttachmentUI>({
      query(data) {
        return {
          url: `${ENoticeUrls.NOTICE}/${ENoticeUrls.ATTACHMENT}`,
          method: 'PATCH',
          body: addAttchmentConvertor.toDb(data),
        };
      },
      invalidatesTags: ['notice'],
    }),
    updateViewdStatus: build.query<void, { noticeId: string }>({
      query(data) {
        return {
          url: `${ENoticeUrls.NOTICE}/${ENoticeUrls.VIEW}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetNoticeListQuery,
  useGetNoticeQuery,
  useCreateNoticeMutation,
  useAddCommentMutation,
  useAddAttchmentMutation,
  useUpdateViewdStatusQuery,
} = noticeApi;
