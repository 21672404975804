import { authSplitApi } from 'redux/helpers/slice.helpers';
import { EPolicyUrls } from 'data/urls/policy.url';
import { docHtmlConvertor } from 'data/convertors/policy.convertors';
import { TDocHtmlUI } from 'data/types/policy.types';

export const policyApi = authSplitApi('policy', ['esaDoc']).injectEndpoints({
  endpoints: build => ({
    getDocHtml: build.query<
      TDocHtmlUI,
      { leasePacketId?: string; url: string }
    >({
      query({ leasePacketId, url }) {
        return {
          url: `${EPolicyUrls.POLICY}/${EPolicyUrls.DOC}/${url}`,
          method: 'GET',
          responseHandler: 'text',
          params: { leasePacketId },
        };
      },
      transformResponse: (data: string) => docHtmlConvertor.fromDb(data),
    }),
  }),
});

export const { useGetDocHtmlQuery } = policyApi;
