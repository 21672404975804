import { TCreateConverter, TFetchConverter } from 'data/types/convertor.types';
import {
  EComplaintTagDB,
  EComplaintTagUI,
  TComplaint,
  TComplaintUI,
  TCreator,
  TViolator,
  EComplaintTypeUI,
  EComplaintTypeDB,
  EComplaintTitleUI,
  EComplaintTitleDB,
  TCommentDataUI,
  TCommentData,
  TAddCommentUI,
  TAddComment,
  TAddAttachmentUI,
  TAddAttachment,
} from 'data/types/complaint.types';
import { formattedDate } from 'helpers/utils';
import { fileConverter } from './general.converters';

export const complaintTagConvertor: TFetchConverter<
  EComplaintTagUI,
  EComplaintTagDB
> = {
  fromDb: data => {
    const dataMapper: { [key in EComplaintTagDB]: EComplaintTagUI } = {
      [EComplaintTagDB.COMPLAINT]: EComplaintTagUI.COMPLAINT,
      [EComplaintTagDB.NOTICE]: EComplaintTagUI.NOTICE,
    };
    return dataMapper[data];
  },
};

export const complaintTitleConvertor: TFetchConverter<
  EComplaintTitleUI,
  EComplaintTitleDB
> = {
  fromDb: data => {
    const dataMapper: { [key in EComplaintTitleDB]: EComplaintTitleUI } = {
      [EComplaintTitleDB.UNAUTHORIZED_ANIMAL]:
        EComplaintTitleUI.UNAUTHORIZED_ANIMAL,
      [EComplaintTitleDB.DAMAGE]: EComplaintTitleUI.DAMAGE,
      [EComplaintTitleDB.NOISE_DISTURBANCE]:
        EComplaintTitleUI.NOISE_DISTURBANCE,
      [EComplaintTitleDB.WASTE_MANAGEMENT]: EComplaintTitleUI.WASTE_MANAGEMENT,
      [EComplaintTitleDB.PET_RULE_VIOLATION]:
        EComplaintTitleUI.PET_RULE_VIOLATION,
      [EComplaintTitleDB.AGGRESSIVE_BEHAVIOR]:
        EComplaintTitleUI.AGGRESSIVE_BEHAVIOR,
      [EComplaintTitleDB.OTHER]: EComplaintTitleUI.OTHER,
    };
    return dataMapper[data];
  },
};

export const complaintTypeConvertor: TFetchConverter<
  EComplaintTypeUI,
  EComplaintTypeDB
> = {
  fromDb: data => {
    const dataMapper: { [key in EComplaintTypeDB]: EComplaintTypeUI } = {
      [EComplaintTypeDB.COMPLAINT]: EComplaintTypeUI.COMPLAINT,
      [EComplaintTypeDB.VIOLATION]: EComplaintTypeUI.VIOLATION,
    };
    return dataMapper[data];
  },
};

export const complaintCreatorConvertor: TFetchConverter<
  { creatorName: string; creatorPhoto: string },
  TCreator
> = {
  fromDb: data => {
    return {
      creatorName: data.creatorName,
      creatorPhoto: data.creatorPhoto,
    };
  },
};

export const complaintViolatorConvertor: TFetchConverter<
  {
    violatorName: string | null;
    violatorPropertyName: string;
    violatorUnitName: string | null;
  },
  TViolator
> = {
  fromDb: data => {
    return {
      violatorName: data.violatorName,
      violatorPropertyName: data.violatorPropertyName,
      violatorUnitName: data.violatorUnitName,
    };
  },
};

export const complaintCommentsConvertor: TFetchConverter<
  TCommentDataUI,
  TCommentData
> = {
  fromDb: data => {
    return {
      commentsCount: data.commentsCount,
      commentsList: data.commentsList.map(comment => ({
        creatorId: comment.creatorId,
        creatorName: comment.creatorName,
        createdAt: formattedDate(`${comment.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
        owenComment: comment.owenComment,
        message: comment.massage,
      })),
    };
  },
};

export const getComplaintConvertor: TFetchConverter<TComplaintUI, TComplaint> =
  {
    fromDb: complaintDetail => ({
      id: complaintDetail.id,
      tag: complaintTagConvertor.fromDb(complaintDetail.tag),
      conversationWith: complaintDetail.conversationWith,
      creator: complaintCreatorConvertor.fromDb({
        creatorName: complaintDetail.reporterName,
        creatorPhoto: complaintDetail.reporterPhoto,
      }),
      violator: complaintViolatorConvertor.fromDb({
        violatorName: complaintDetail.violatorName,
        violatorPropertyName: complaintDetail.violatorPropertyName,
        violatorUnitName: complaintDetail.violatorUnitName,
      }),
      createdAt: formattedDate(
        `${complaintDetail.createdAt}Z`,
        'MM/DD/YYYY hh:mm A',
      ),
      type: complaintTypeConvertor.fromDb(complaintDetail.type),
      title: complaintTitleConvertor.fromDb(complaintDetail.title),
      description: complaintDetail.description,
      hasUnreadComment: complaintDetail.hasUnreadComment,
      attachments: complaintDetail.attachments.map(attachment =>
        fileConverter.fromDb(attachment),
      ),
      comments: complaintCommentsConvertor.fromDb({
        commentsCount: complaintDetail.commentsCount,
        commentsList: complaintDetail.comments,
      }),
      viewed: complaintDetail.viewed,
    }),
  };

export const addCommentConvertor: TCreateConverter<TAddCommentUI, TAddComment> =
  {
    toDb: data => ({
      complaintId: data.complaintId,
      message: data.comment,
    }),
  };

export const addAttchmentConvertor: TCreateConverter<
  TAddAttachmentUI,
  TAddAttachment
> = {
  toDb: data => ({
    complaintId: data.complaintId,
    attachments: data.attachments.map(item => fileConverter.toDb(item)) || [],
  }),
};
