import { Menu } from 'components/Base/Menu';
import Dropdown from 'components/Base/Dropdown';
import {
  menuActionItems,
  menuPathItems,
} from 'components/Base/Layout/Header/Header.data';
import { TProfileDropDownMenuProps } from './ProfileDropdownMenu.types';
import MenuPathItem from '../../../MenuItem/MenuPathItem';
import MenuActionItem from '../../../MenuItem/MenuActionItem';

const ProfileDropdownMenu = ({
  children,
  onClick,
}: TProfileDropDownMenuProps) => {
  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      overlayClassName="profile-menu"
      overlay={
        <Menu>
          {menuPathItems.map(item => {
            return <MenuPathItem key={item.path} menuItem={item} />;
          })}
          {menuActionItems.map(item => (
            <MenuActionItem
              key={item.actionType}
              onClick={onClick}
              menuItem={item}
            />
          ))}
        </Menu>
      }
    >
      {children}
    </Dropdown>
  );
};

export default ProfileDropdownMenu;
