import React from 'react';
import { defaultDateFormat } from 'helpers/utils';
import { Screens } from 'core/CssVariables';
import { useMediaQuery } from 'react-responsive';
import {
  SDatePicker,
  SDateRangePicker,
  SMobileDatePicker,
} from './DatePicker.style';
import { TDatePickerProps, TDateRangePickerProps } from './DatePicker.type';

const DateRangePicker: React.FC<TDateRangePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  return <SDateRangePicker format={format} {...props} />;
};

const DatePicker: React.FC<TDatePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  return <SDatePicker format={format} {...props} />;
};

const MobileDatePicker: React.FC<TDatePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  return <SMobileDatePicker format={format} {...props} />;
};

const AdaptiveDatePicker: React.FC<TDatePickerProps> = ({
  format = defaultDateFormat,
  ...props
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });
  if (isMobile) {
    return (
      <MobileDatePicker
        {...(props.disabled && {
          style: {
            pointerEvents: 'none',
          },
        })}
        format={format}
        {...props}
      />
    );
  }
  return <DatePicker format={format} {...props} />;
};

export { DateRangePicker, DatePicker, AdaptiveDatePicker };
