import React from 'react';
import {
  TLinkProps,
  TParagraphProps,
  TTextProps,
  TTitleProps,
} from './Typography.types';
import {
  SLink,
  SParagraph,
  SSecondaryText,
  SText,
  STitle,
} from './Typography.styles';

export const Title = (props: TTitleProps) => {
  return <STitle {...props} />;
};

export const Text = (props: TTextProps) => {
  return <SText {...props} />;
};

export const SecondaryText = (props: TTextProps) => {
  return <SSecondaryText {...props} />;
};

export const Link = (props: TLinkProps) => {
  return <SLink role="link" tabIndex={0} {...props} />;
};

export const Paragraph = (props: TParagraphProps) => {
  return <SParagraph {...props} />;
};
