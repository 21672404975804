import { Screens } from 'core/CssVariables';
import { useMediaQuery } from 'react-responsive';
import { useGetTenantProfileQuery } from 'redux/apiSlice/tenant.slices';
import MobileHeader from './components/MobileHeader';
import DesktopHeader from './components/DesktopHeader';

const Header = () => {
  useGetTenantProfileQuery(null);
  const isMobile = useMediaQuery({
    query: `(max-width: ${Screens.ScreensMD})`,
  });

  return <>{isMobile ? <MobileHeader /> : <DesktopHeader />}</>;
};

export default Header;
