import { TFileDB, TFileUI, TOptionTypeUi } from './generalTypes';

export enum ENoticeTitleDB {
  UNAUTHORIZED_ANIMAL = 'UNAUTHORIZED_ANIMAL',
  DAMAGE = 'DAMAGE',
  NOISE_DISTURBANCE = 'NOISE_DISTURBANCE',
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  PET_RULE_VIOLATION = 'PET_RULE_VIOLATION',
  AGGRESSIVE_BEHAVIOR = 'AGGRESSIVE_BEHAVIOR',
  OTHER = 'OTHER',
}

export enum ENoticeTypeDB {
  COMPLAINT = 'COMPLAINT',
  VIOLATION = 'VIOLATION',
}

export enum ENoticeTagDB {
  COMPLAINT = 'COMPLAINT',
  NOTICE = 'NOTICE',
}

export enum ENoticeTypeUI {
  COMPLAINT = 'Complaint',
  VIOLATION = 'Violation',
}

export enum ENoticeTagUI {
  COMPLAINT = 'Complaint',
  NOTICE = 'Notice',
}

export enum ENoticeTitleUI {
  UNAUTHORIZED_ANIMAL = 'Unauthorized animal',
  DAMAGE = 'Damage',
  NOISE_DISTURBANCE = 'Noise disturbance',
  WASTE_MANAGEMENT = 'Waste management',
  PET_RULE_VIOLATION = 'Pet rule violation',
  AGGRESSIVE_BEHAVIOR = 'Aggressive behavior',
  OTHER = 'Other',
}

export type TNoticeListUI = {
  id: string;
  creatorName: string;
  createdAt: string;
  type: ENoticeTypeUI;
  tag: ENoticeTagUI;
  title: ENoticeTitleUI;
  description: string;
  commentsCount: number;
  hasUnreadComment: boolean;
  viewed: boolean;
};

export type TNoticeList = {
  id: string;
  reporterName: string;
  createdAt: string;
  type: ENoticeTypeDB;
  tag: ENoticeTagDB;
  title: ENoticeTitleDB;
  description: string;
  commentsCount: number;
  hasUnreadComment: boolean;
  viewed: boolean;
};

export type TCreator = {
  creatorName: string;
  creatorPhoto: string;
};

export type TViolator = {
  violatorPropertyName: string;
  violatorName: string | null;
  violatorUnitName: string | null;
};

export type TCommentUI = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  message: string;
};
export type TComment = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  massage: string;
};

export type TCommentDataUI = {
  commentsCount: number;
  commentsList: TCommentUI[];
};
export type TCommentData = {
  commentsCount: number;
  commentsList: TComment[];
};

export type TNoticeUI = {
  id: string;
  tag: ENoticeTagUI;
  createdAt: string;
  type: ENoticeTypeUI;
  title: ENoticeTitleUI;
  description: string;
  hasUnreadComment: boolean;
  attachments: TFileUI[];
  viewed: boolean;
  creator: TCreator;
  violator: TViolator;
  comments: TCommentDataUI;
  conversationWith: string;
};

export type TNotice = {
  id: string;
  tag: ENoticeTagDB;
  reporterName: string;
  reporterPhoto: string;
  createdAt: string;
  violatorName: string;
  violatorPropertyName: string;
  violatorUnitName: string;
  type: ENoticeTypeDB;
  title: ENoticeTitleDB;
  description: string;
  commentsCount: number;
  hasUnreadComment: boolean;
  attachments: TFileDB[];
  comments: TComment[];
  viewed: boolean;
  conversationWith: string;
};

export type TAddAttachmentUI = {
  noticeId: string;
  attachments: TFileUI[];
};
export type TAddAttachment = {
  noticeId: string;
  attachments: TFileDB[];
};

export type TAddCommentUI = {
  noticeId: string;
  comment: string;
};
export type TAddComment = {
  noticeId: string;
  message: string;
};

export type TNoticeCreate = {
  title: ENoticeTitleDB;
  description: string;
  propertyId: string;
  unitName?: string;
  violatorName?: string;
  attachments: TFileDB[];
};

export type TNoticeCreateUI = {
  title: TOptionTypeUi;
  description: string;
  property: TOptionTypeUi;
  unitName?: string;
  violatorName?: string;
  attachments: TFileUI[];
};
