import { authSplitApi } from 'redux/helpers/slice.helpers';
import { downloadFileData } from 'api/sevices/fileServices';

export const fileApi = authSplitApi('file').injectEndpoints({
  endpoints: build => ({
    downloadFile: build.mutation<null, { url: string; name: string }>({
      // @ts-ignore
      queryFn({ url, name }) {
        try {
          downloadFileData(url, name);
          return { data: null };
        } catch (e) {
          return { error: e };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useDownloadFileMutation } = fileApi;
