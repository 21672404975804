import {
  TAnimalAggressiveBehaviorDB,
  TAnimalAggressiveBehaviorUI,
} from 'data/types/animal.types';
import {
  TConvertor,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/convertor.types';
import {
  EAnimalStatuses,
  EAnimalStatusesUi,
  EApplicationStatuses,
  EApplicationStatusesUi,
  EFailType,
  EFailTypeUI,
  EFileTypeDB,
  EFileTypeUI,
  EGender,
  EGenderUi,
  EValueEditingStates,
  EValueEditingStatesUi,
  EVerificationStatus,
  EVerificationStatusUi,
  TCustomOptionTypeUi,
  TCustomSpeciesOptionTypeUi,
  TEditingBooleanField,
  TEditingBooleanFieldUi,
  TEditingField,
  TEditingFieldUi,
  TEditingYesNoField,
  TEditingYesNoFieldUi,
  TFail,
  TFailUI,
  TFileDB,
  TFileUI,
  TOptionType,
  TOptionTypeUi,
  TPredefinedOptionType,
  TPredefinedOptionTypeUi,
  TProfilePictureUi,
  TSelectValue,
  TSelectValueUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TYesNoOptionType,
} from 'data/types/generalTypes';
import { dateTimeFormat, formattedDate } from 'helpers/utils';

export const phoneNumberConverter: TConvertor<
  string | null | undefined,
  string | null
> = {
  toDb: phoneNumber =>
    phoneNumber ? `${phoneNumber[0] === '+' ? '' : '+'}${phoneNumber}` : null,
  fromDb: phoneNumber => phoneNumber,
};

export const yesNoOptionConverter: TConvertor<
  TYesNoOptionType,
  boolean | null | undefined
> = {
  fromDb: data => {
    if (data) {
      return 'Yes';
    }
    if (data === false) {
      return 'No';
    }
    return null;
  },
  toDb: data => {
    if (data === 'Yes') {
      return true;
    }
    if (data === 'No') {
      return false;
    }
    return null;
  },
};

export const fileTypeConverter: TConvertor<EFileTypeUI, EFileTypeDB> = {
  fromDb: data => {
    const dataMapper: { [key in EFileTypeDB]: EFileTypeUI } = {
      [EFileTypeDB.PHOTO]: EFileTypeUI.PHOTO,
      [EFileTypeDB.VIDEO]: EFileTypeUI.VIDEO,
      [EFileTypeDB.OTHER]: EFileTypeUI.OTHER,
    };
    return dataMapper[data];
  },
  toDb: data => {
    const dataMapper: { [key in EFileTypeUI]: EFileTypeDB } = {
      [EFileTypeUI.PHOTO]: EFileTypeDB.PHOTO,
      [EFileTypeUI.VIDEO]: EFileTypeDB.VIDEO,
      [EFileTypeUI.OTHER]: EFileTypeDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const applicationStatusConvertor: TFetchConverter<
  EApplicationStatusesUi,
  EApplicationStatuses
> = {
  fromDb: data => {
    const status: { [key in EApplicationStatuses]: EApplicationStatusesUi } = {
      [EApplicationStatuses.APPROVED]: EApplicationStatusesUi.APPROVED,
      [EApplicationStatuses.PENDING]: EApplicationStatusesUi.PENDING,
      [EApplicationStatuses.REJECTED]: EApplicationStatusesUi.REJECTED,
      [EApplicationStatuses.ARCHIVED]: EApplicationStatusesUi.ARCHIVED,
      [EApplicationStatuses.RETRACTED]: EApplicationStatusesUi.RETRACTED,
      [EApplicationStatuses.MOVED_OUT]: EApplicationStatusesUi.MOVED_OUT,
      [EApplicationStatuses.MOVED_IN]: EApplicationStatusesUi.MOVED_IN,
      [EApplicationStatuses.CONDITIONALLY_APPROVED]:
        EApplicationStatusesUi.CONDITIONALLY_APPROVED,
      [EApplicationStatuses.CONDITIONALLY_MOVED_IN]:
        EApplicationStatusesUi.CONDITIONALLY_MOVED_IN,
    };
    return status[data];
  },
};

export const verificationStatusConverter: TFetchConverter<
  EVerificationStatusUi,
  EVerificationStatus
> = {
  fromDb: data => {
    const statuses: {
      [key in EVerificationStatus]: EVerificationStatusUi;
    } = {
      [EVerificationStatus.VERIFIED]: EVerificationStatusUi.VERIFIED,
      [EVerificationStatus.NONE]: EVerificationStatusUi.NOT_VERIFIED,
    };
    return statuses[data];
  },
};

export const animalStatusConverter: TConvertor<
  EAnimalStatusesUi,
  EAnimalStatuses
> = {
  fromDb: data => {
    const statuses: {
      [key in EAnimalStatuses]: EAnimalStatusesUi;
    } = {
      [EAnimalStatuses.INCOMPLETE]: EAnimalStatusesUi.INCOMPLETE,
      [EAnimalStatuses.PENDING]: EAnimalStatusesUi.PENDING,
      [EAnimalStatuses.NOT_VERIFIED]: EAnimalStatusesUi.NOT_VERIFIED,
      [EAnimalStatuses.VERIFIED]: EAnimalStatusesUi.VERIFIED,
      [EAnimalStatuses.AUTHORIZED]: EAnimalStatusesUi.AUTHORIZED,
      [EAnimalStatuses.UN_AUTHORIZED]: EAnimalStatusesUi.UN_AUTHORIZED,
    };
    return statuses[data];
  },
  toDb: data => {
    const statuses: { [key in EAnimalStatusesUi]: EAnimalStatuses } = {
      [EAnimalStatusesUi.INCOMPLETE]: EAnimalStatuses.INCOMPLETE,
      [EAnimalStatusesUi.PENDING]: EAnimalStatuses.PENDING,
      [EAnimalStatusesUi.NOT_VERIFIED]: EAnimalStatuses.NOT_VERIFIED,
      [EAnimalStatusesUi.VERIFIED]: EAnimalStatuses.VERIFIED,
      [EAnimalStatusesUi.AUTHORIZED]: EAnimalStatuses.AUTHORIZED,
      [EAnimalStatusesUi.UN_AUTHORIZED]: EAnimalStatuses.UN_AUTHORIZED,
    };
    return statuses[data];
  },
};

export const fieldEditingStatesConvertor: TConvertor<
  EValueEditingStatesUi,
  EValueEditingStates
> = {
  fromDb: data => {
    return EValueEditingStatesUi[
      EValueEditingStates[data] as keyof typeof EValueEditingStatesUi
    ];
  },
  toDb: data => {
    return EValueEditingStates[
      EValueEditingStatesUi[data] as keyof typeof EValueEditingStates
    ];
  },
};

export const editingFieldConvertor: TFetchConverter<
  TEditingFieldUi,
  TEditingField
> = {
  fromDb: data => {
    return {
      value: data.value || '',
      id: data?.predefinedId || '',
      canEdit: !!data.allowedOperations?.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: !!data.allowedOperations?.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
      canAdd: !!data.allowedOperations?.some(
        item => item === EValueEditingStates.ADD,
      ),
    };
  },
};

export const aggressiveBehaviorConverter: TFetchConverter<
  TAnimalAggressiveBehaviorUI,
  TAnimalAggressiveBehaviorDB
> = {
  fromDb: data => {
    return {
      acted: yesNoOptionConverter.fromDb(data.acted),
      incidentDetails: data?.incidentDetails || '',
      canEdit: !!data.allowedOperations?.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
      canRemove: !!data.allowedOperations?.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.REMOVE].includes(item),
      ),
      canAdd: !!data.allowedOperations?.some(
        item => item === EValueEditingStates.ADD,
      ),
    };
  },
};

export const selectValueConvertor: TFetchConverter<
  TSelectValueUI,
  TSelectValue
> = {
  fromDb: data => {
    return data?.value
      ? {
          value: data.predefinedId || data.value,
          label: data.value,
          __isNew__: !data.predefinedId,
        }
      : undefined;
  },
};

export const editingBooleanFieldConvertor: TFetchConverter<
  TEditingBooleanFieldUi,
  TEditingBooleanField
> = {
  fromDb: data => {
    return {
      value: !!data.value,
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
    };
  },
};

export const editingYesNoFieldConvertor: TFetchConverter<
  TEditingYesNoFieldUi,
  TEditingYesNoField
> = {
  fromDb: data => {
    return {
      value: yesNoOptionConverter.fromDb(data.value),
      canEdit: data.allowedOperations.some(item =>
        [EValueEditingStates.SET, EValueEditingStates.UPDATE].includes(item),
      ),
    };
  },
};

export const genderEnumConvertor: TConvertor<EGenderUi, EGender> = {
  fromDb: data => {
    return EGenderUi[EGender[data] as keyof typeof EGenderUi];
  },
  toDb: data => {
    const convertedData = data.toUpperCase() as keyof typeof EGenderUi;
    return EGender[
      EGenderUi[convertedData].toUpperCase() as keyof typeof EGender
    ];
  },
};

export const selectOptionConverter: TConvertor<TOptionTypeUi, TOptionType> = {
  fromDb: data => {
    return {
      id: data.id || '',
      value: data.id || '',
      label: data.value || '',
    };
  },
  toDb: data => {
    return {
      ...(data.__isNew__ ? { value: data.label } : { id: data.value }),
    };
  },
};

export const profilePictureConvertor: TCreateConverter<
  TProfilePictureUi,
  string
> = {
  toDb: data => data.url,
};

export const fileConverter: TConvertor<TFileUI, TFileDB> = {
  fromDb: data => {
    return {
      name: data.name,
      url: data.url,
      type: data.type ? fileTypeConverter.fromDb(data.type) : EFileTypeUI.OTHER,
    };
  },
  toDb: data => {
    return {
      name: data.name,
      url: data.url,
      ...(data.type && { type: fileTypeConverter.toDb(data.type) }),
    };
  },
};

export const selectOptionPredefinedConverter: TFetchConverter<
  TPredefinedOptionTypeUi,
  TPredefinedOptionType
> = {
  fromDb: data => {
    return {
      id: data.predefinedId || '',
      value: data.predefinedId || '',
      label: data.value || '',
      ...(!data.predefinedId && { __isNew__: true }),
    };
  },
};

export const selectCustomOptionConverter: TFetchConverter<
  TCustomOptionTypeUi,
  TOptionTypeUi
> = {
  fromDb: data => {
    return {
      id: null,
      value: data.label,
    };
  },
};

export const selectCustomSpeciesOptionConverter: TFetchConverter<
  TCustomSpeciesOptionTypeUi,
  TOptionTypeUi
> = {
  fromDb: data => {
    return {
      id: null,
      value: data.label,
      predefined: false,
    };
  },
};

export const failTypeConvertor: TConvertor<EFailTypeUI, EFailType> = {
  fromDb: data => EFailTypeUI[EFailType[data] as keyof typeof EFailTypeUI],
  toDb: data => EFailType[EFailTypeUI[data] as keyof typeof EFailType],
};

export const failsConvertor: TFetchConverter<TFailUI[], TFail> = {
  fromDb: data =>
    Object.keys(data).map(key => ({
      field: key,
      reason: data[key].reason,
      type: failTypeConvertor.fromDb(data[key].type),
    })),
};
export const statusChangeInfoConverter: TFetchConverter<
  TStatusChangeInfoUI,
  TStatusChangeInfoDB
> = {
  fromDb: data => {
    return {
      by: data.by,
      reason: data.reason,
      date: data.date && formattedDate(data.date, dateTimeFormat),
      dueDate: data.dueDate && formattedDate(data.dueDate, dateTimeFormat),
    };
  },
};

export const emailMaskConverter: TFetchConverter<string, string> = {
  fromDb: data => {
    return data.replace(
      /^(.{2})(.*)(.{2})(?=@)/,
      (_, firstTwoChars, middleChars, lastTwoChars) =>
        `${firstTwoChars}${'*'.repeat(middleChars.length)}${lastTwoChars}`,
    );
  },
};
