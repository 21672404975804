import React, { FC, useEffect } from 'react';
import { useAppSelector } from 'redux/store';

export const ImpersonateUpdatesLayer: FC = ({ children }) => {
  const { impersonateByTenant } = useAppSelector(state => state.userSlice);

  const onStorageListen = (e: StorageEvent) => {
    if (e.key === 'tenantId' && e.newValue !== impersonateByTenant) {
      const href = `${window.location.origin}/impersonation/${e.newValue}`;
      window.open(href, '_self', '');
    }
  };
  useEffect(() => {
    window.addEventListener('storage', onStorageListen);
    return () => {
      window.removeEventListener('storage', onStorageListen);
    };
  }, []);

  return <>{children}</>;
};

export default ImpersonateUpdatesLayer;
