export enum EResidencyUrls {
  RESIDENCY = 'residency',
  APPLICATION = 'application',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  UNIT = 'unit',
  VERIFY = 'verify',
  MOVE_IN_DATE = 'move-in-date',
  FIRST_TIME_FACE = 'first-time-face',
  ANIMAL_COUNT = 'animal-count',
}
